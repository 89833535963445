// ** Third Party Imports
import styled from 'styled-components'

import { textTrunc } from '@/shared/styles'

interface TypographyProps {
	link?: boolean
	weight?: 'regular' | 'medium' | 'semi-bold' | 'bold'
	variant?: 'body1' | 'body2' | 'body3' | 'caption'
	$textTrunc?: number
	$whiteSpace?:
		| 'normal'
		| 'nowrap'
		| 'pre'
		| 'pre-wrap'
		| 'pre-line'
		| 'break-spaces'
}

export const TypographyStyled = styled.p<TypographyProps>`
	color: ${({ theme }) => theme.pallette.text.black};

	// Text weight
	${({ weight }) => weight === 'regular' && `font-weight: 400;`}

	${({ weight }) => weight === 'medium' && `font-weight: 500;`}

  ${({ weight }) => weight === 'semi-bold' && `font-weight: 600;`}

  ${({ weight }) => weight === 'bold' && `font-weight: 700;`}

  // Text variant/size
	${({ variant }) => variant === 'body1' && `font-size: 1rem;`}

	${({ variant }) => variant === 'body2' && `font-size: 0.875rem;`}

	${({ variant }) => variant === 'body3' && `font-size: 0.75rem;`}

	${({ variant }) => variant === 'caption' && `font-size: 0.625rem;`}

  // Text decoration
	${({ link }) => link === true && `text-decoration-line: underline;`}

	${({ $textTrunc }) => $textTrunc && textTrunc($textTrunc)}

  ${({ $whiteSpace }) => $whiteSpace && `white-space: ${$whiteSpace};`}
`
