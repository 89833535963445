// ** Root Slice & Helper Functions
import { TAGS, baseApi, customErrorHandler } from '@/shared/api'

// ** Type imports
import { type UserDTO } from '@/entities/user'
import { type LoginVariables } from '../model/types'

export const sessionApi = baseApi.injectEndpoints({
	endpoints: (build) => ({
		login: build.mutation<UserDTO, LoginVariables>({
			query: (body) => ({
				url: '/auth/login',
				method: 'POST',
				body
			}),
			transformErrorResponse: customErrorHandler,
			invalidatesTags: [TAGS.ME]
		}),
		logout: build.mutation<void, void>({
			query: () => ({
				url: '/auth/logout',
				method: 'POST'
			}),
			transformErrorResponse: customErrorHandler,
			invalidatesTags: [TAGS.ME]
		})
	})
})

export const { useLoginMutation, useLogoutMutation } = sessionApi
