// ** React Imports
import { type ReactNode, useEffect } from 'react'

// ** Component Imports
import { FallbackLoader } from '@/shared/ui'

// ** Store & Actions
import { currentUserThunk } from '@/entities/user'
import { useAppSelector, useAppDispatch } from '@/shared/lib/hooks'

interface IProps {
	children: ReactNode
}

export const AuthWrapper = ({ children }: IProps) => {
	// ** Hooks
	const dispatch = useAppDispatch()
	const isLoading = useAppSelector((state) => state.session.isVerifyingToken)

	useEffect(() => {
		dispatch(currentUserThunk())
	}, [])

	if (isLoading) {
		return <FallbackLoader />
	}

	return <>{children}</>
}
