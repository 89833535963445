// ** Third Party Imports
import { useRoutes } from 'react-router-dom'
import { wrapUseRoutes } from '@sentry/react'

// ** Hook Imports
// Uncomment the line below after the launch phase
// import { useAppSelector } from '@/shared/lib/hooks'

// ** Route Imports
import { publicRoutes } from './publicRoutes'
// Uncomment the line below after the launch phase
// import { protectedRoutes } from './protectedRoutes'

export const AppRoutes = () => {
	// ** Hooks
	// Uncomment the line below after the launch phase
	// const isAuth = useAppSelector((state) => state.session.isAuthenticated)

	const routes = publicRoutes
	/**
	 * Uncomment the line below and delete the line above after the launch phase
	 * This enables only public routes to be available during the launch phase
	 */
	// const routes = isAuth ? protectedRoutes : publicRoutes

	const useSentryRoutes = wrapUseRoutes(useRoutes)
	const element = useSentryRoutes(routes)

	return <>{element}</>
}
