// ** Third Party Imports
import * as yup from 'yup'

// ** Utility Imports
import { EMAIL, PHONE_NUMBER } from '@/shared/lib'

// ** Type Imports
import { type ScheduleDemoSchema } from './types'

export const schema: yup.ObjectSchema<ScheduleDemoSchema> = yup.object().shape({
	firstName: yup.string().required('This is a required field.'),
	lastName: yup.string().required('This is a required field.'),
	email: yup
		.string()
		.matches(EMAIL, 'Email must be a valid email.')
		.required('This is a required field.'),
	phoneNum: yup
		.string()
		.required('This is a required field.')
		.matches(PHONE_NUMBER, 'Number must be a valid phone number'),
	revenue: yup
		.number()
		.min(0, 'Must be a positive number.')
		.required('This is a required field.')
		.typeError('This field must be a number.'),
	numOfClients: yup
		.number()
		.min(0, 'Must be a positive number.')
		.required('This is a required field.')
		.typeError('This field must be a number.')
})
