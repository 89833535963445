// ** React Imports
import { type ReactNode } from 'react'

// ** Third Party Imports
import * as Form from '@radix-ui/react-form'

interface IProps {
	/**
	 * Form submit handler:
	 * In order to submit the form, you must use
	 * the Button component with submit type, and submit
	 * handler function passed as a prop to FormWrapper component
	 */
	onSubmit?: (...args: any) => any
	children: ReactNode
	className?: string
	name?: string
	autoComplete?: string
	id?: string
}

export const FormWrapper = ({
	onSubmit,
	children,
	className,
	name,
	autoComplete,
	id
}: IProps) => {
	return (
		<Form.Root
			onSubmit={onSubmit}
			className={className}
			noValidate
			autoComplete={autoComplete}
			name={name}
			id={id}
		>
			{children}
		</Form.Root>
	)
}
