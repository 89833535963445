// ** React Imports
import React from 'react'
import ReactDOM from 'react-dom/client'

// ** Third Party Imports
import ReactGA from 'react-ga4'
import * as Sentry from '@sentry/react'
import 'react-toastify/dist/ReactToastify.min.css'
import { Provider as ReduxProvider } from 'react-redux'
import {
	matchRoutes,
	useLocation,
	useNavigationType,
	BrowserRouter as Router,
	createRoutesFromChildren
} from 'react-router-dom'

// ** Redux Store
import { store } from '@/app/config/store'

// ** Component Imports
import { ReactToast, ScrollToTop } from '@/shared/ui'

// ** Utility Imports
import { env } from '@/shared/lib'

// ** Style Imports
import { GlobalStyle } from './styles'

// ** Providers
import { AppRoutes } from './config/routes'
import { AuthWrapper } from '@/entities/session'
import { ThemeProvider } from './providers'

Sentry.init({
	// Disable Sentry in development mode
	dsn: import.meta.env.MODE === 'development' ? undefined : env.sentryDsnKey,
	environment: import.meta.env.MODE,
	integrations: [
		// See docs for support of different versions of variation of react router
		// https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
		Sentry.reactRouterV6BrowserTracingIntegration({
			useEffect: React.useEffect,
			useLocation,
			useNavigationType,
			createRoutesFromChildren,
			matchRoutes
		}),
		Sentry.replayIntegration()
	],

	// Set tracesSampleRate to 1.0 to capture 100%
	// of transactions for performance monitoring.
	tracesSampleRate: 1.0,

	// Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
	tracePropagationTargets: ['localhost', env.apiUrl],

	// Capture Replay for 10% of all sessions,
	// plus for 100% of sessions with an error
	replaysSessionSampleRate: 0.1,
	replaysOnErrorSampleRate: 1.0
})

ReactGA.initialize(env.googleTrackingId)

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
	<React.StrictMode>
		<Sentry.ErrorBoundary>
			<ReduxProvider store={store}>
				<ThemeProvider>
					<GlobalStyle />
					<ReactToast />
					<AuthWrapper>
						<Router>
							<ScrollToTop />
							<AppRoutes />
						</Router>
					</AuthWrapper>
				</ThemeProvider>
			</ReduxProvider>
		</Sentry.ErrorBoundary>
	</React.StrictMode>
)
