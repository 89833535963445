// ** React Imports
import { type ReactNode } from 'react'

// ** Third Party Imports
import styled from 'styled-components'
import * as Accordion from '@radix-ui/react-accordion'

// ** Icon Imports
import { ChevronRight } from '../../icons'

interface IProps {
	color?: 'primary' | 'secondary'
	children: ReactNode
	arrowHidden?: boolean
}

export const AccordionTrigger = ({
	color = 'primary',
	children,
	arrowHidden = false
}: IProps) => {
	return (
		<StyledHeader>
			<StyledTrigger $color={color}>
				{children}
				{!arrowHidden && <StyledChevron />}
			</StyledTrigger>
		</StyledHeader>
	)
}

// Accordion Trigger Styled Components
export const StyledHeader = styled(Accordion.Header)`
	all: unset;
	display: flex;
`

export const StyledTrigger = styled(Accordion.Trigger)<{
	$color: 'primary' | 'secondary'
}>`
	all: unset;
	width: 100%;
	display: flex;
	padding: 1rem;
	align-items: center;
	justify-content: space-between;
	${({ $color, theme }) =>
		`background-color: ${
			$color === 'primary'
				? theme.pallette.primary.greenBackground50
				: theme.pallette.secondary.yellowBackground
		}
		};`}

	${({ $color, theme }) =>
		$color === 'secondary' &&
		` border: 1px solid ${theme.pallette.secondary.yellow};
      border-top-right-radius: 0.5rem;
      border-top-left-radius: 0.5rem;
    `}
`

export const StyledChevron = styled(ChevronRight)`
	[data-state='open'] & {
		transform: rotate(90deg);
	}
`
