import { createSlice, type PayloadAction } from '@reduxjs/toolkit'

interface LayoutState {
	isSidebarOpen: boolean
}

const initialState: LayoutState = {
	isSidebarOpen: false
}

const layoutSlice = createSlice({
	name: 'layout',
	initialState,
	reducers: {
		setIsSidebarOpen(state, action: PayloadAction<boolean>) {
			state.isSidebarOpen = action.payload
		}
	}
})

export const { setIsSidebarOpen } = layoutSlice.actions

export const layoutReducer = layoutSlice.reducer
