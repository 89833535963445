// ** React Imports
import { type ReactNode } from 'react'

// ** Styled Components
import { CardStyled } from './styles'

interface IProps {
	/**
	 * Custom inline styling
	 */
	style?: React.CSSProperties | undefined
	/**
	 * Card content
	 */
	children: ReactNode
	/**
	 * Custom css class
	 */
	className?: string
}

export const Card = ({ style, children, className }: IProps) => {
	return (
		<CardStyled style={style} className={className}>
			{children}
		</CardStyled>
	)
}
