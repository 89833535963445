// ** Third Party Imports
import styled from 'styled-components'

export const YearPickerWrapper = styled.div<{
	$isError: boolean
	startDate: Date | null
	$fullWidth: boolean
}>`
	display: flex;
	position: relative;
	font-family: inherit;

	${({ $fullWidth }) =>
		$fullWidth &&
		`
    & .react-datepicker-wrapper {
      width: 100%;

      & input {
        width: 100%;
      }
    }
  `}

	/* Custom dropdown icon styling */
	& svg {
		top: 0.938rem;
		right: 0.625rem;
		width: 1rem;
		height: 1rem;
		cursor: pointer;
		position: absolute;

		/* Change icon color based on selected value */
		& path {
			stroke: ${({ theme, startDate }) =>
				startDate != null
					? theme.pallette.success.main
					: theme.pallette.text.black50};
		}
	}

	/* Custom date picker styling */
	& .react-datepicker-wrapper {
		/* Custom input wrapper class */
		& .custom-year-input {
			/* React date picker label */
			& label {
				top: 0.813rem;
				left: 0.75rem;
				position: absolute;
				font-size: 0.857rem;
				transition: all 0.2s ease;
				background-color: ${({ theme }) => theme.pallette.geniiWhite};
				font-weight: 400;
				/* Change label color based on selected value */
				color: ${({ theme, startDate }) =>
					startDate != null
						? theme.pallette.success.main
						: theme.pallette.text.black50};
			}

			/* React date picker input field */
			& input {
				cursor: pointer;
				border: 1px solid;
				outline: none;
				${({ $fullWidth }) => !$fullWidth && `max-width: 5rem;`}
				padding: 0.75rem;
				box-shadow: 0px 1px 3px 0px rgba(132, 136, 152, 0.15);
				background: ${({ theme }) => theme.pallette.geniiWhite};
				border-radius: 0.5rem;
				/* Change input border color based on selected value */
				border-color: ${({ theme, startDate }) =>
					startDate != null
						? theme.pallette.success.main
						: theme.pallette.text.black50};

				// Floating label on focus
				&:focus,
				&:not(:placeholder-shown) {
					border-color: ${({ theme, startDate }) =>
						startDate != null
							? theme.pallette.success.main
							: theme.pallette.text.black75};

					~ label {
						top: -0.438rem;
						left: 0.75rem;
						padding: 0 0.25rem;
						font-size: 0.625rem;
						color: ${({ theme, startDate }) =>
							startDate != null
								? theme.pallette.success.main
								: theme.pallette.text.black75};
					}

					~ svg {
						& path {
							stroke: ${({ theme, startDate }) =>
								startDate != null
									? theme.pallette.success.main
									: theme.pallette.text.black75};
						}
					}
				}

				/* Invalid styling */
				${({ $isError, theme }) =>
					$isError &&
					`
            border-color: ${theme.pallette.error.main} !important;

            ~ label {
              color: ${theme.pallette.error.main} !important;
            }

            ~ svg {
              path {
                stroke: ${theme.pallette.error.main} !important;
              }
            }
          `}
			}
		}
	}
`
