// ** Third Party Imports
import styled from 'styled-components'

export const ChatLoader = styled.div`
	display: flex;
	justify-content: center;

	& > div {
		width: 0.5rem;
		height: 0.5rem;
		margin: 1.5px 3px;
		opacity: 1;
		animation: bouncing-loader 0.6s infinite alternate;
		border-radius: 50%;
		background-color: ${({ theme }) => theme.pallette.secondary.yellow};
	}

	@keyframes bouncing-loader {
		to {
			opacity: 0.2;
		}
	}

	& > div:nth-child(2) {
		animation-delay: 0.2s;
	}

	& > div:nth-child(3) {
		animation-delay: 0.4s;
	}
`
