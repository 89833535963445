// ** Third Party Imports
import styled from 'styled-components'
import * as Form from '@radix-ui/react-form'

export const FormFieldStyled = styled(Form.FormField)`
	position: relative;
	text-align: left;
	margin-bottom: 1rem;

	& .input-start-icon {
		top: 1rem;
		left: 0.75rem;
		position: absolute;
	}

	& .input-end-icon {
		top: 1rem;
		right: 0.75rem;
		position: absolute;

		&:hover {
			> svg {
				path {
					stroke: #091231;
				}
			}
		}
	}
`
