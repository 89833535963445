// ** Third Party Imports
import styled from 'styled-components'

interface HeadingProps {
	weight: 'regular' | 'medium' | 'semi-bold' | 'bold'
	variant: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'
}

export const HeadingStyled = styled.div<HeadingProps>`
	color: ${({ theme }) => theme.pallette.text.black};

	// Text weight
	${({ weight }) => weight === 'regular' && `font-weight: 400;`}

	${({ weight }) => weight === 'medium' && `font-weight: 500;`}

  ${({ weight }) => weight === 'semi-bold' && `font-weight: 600;`}

  ${({ weight }) => weight === 'bold' && `font-weight: 700;`}

  // Text variant/size
	${({ variant }) => variant === 'h1' && `font-size: 2.1875rem;`}

	${({ variant }) => variant === 'h2' && `font-size: 1.8125rem;`}

	${({ variant }) => variant === 'h3' && `font-size: 1.5rem;`}

	${({ variant }) => variant === 'h4' && `font-size: 1.25rem;`}
`
