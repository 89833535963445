// ** React Imports
import { type ReactNode } from 'react'

// ** Third Party Imports
import { ThemeProvider as StyledThemeProvider } from 'styled-components'

// ** Config Imports
import { themeConfig } from '../config/theme'

interface IProps {
	children: ReactNode
}

export const ThemeProvider = ({ children }: IProps) => (
	<StyledThemeProvider theme={themeConfig}>{children}</StyledThemeProvider>
)
