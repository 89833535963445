// ** Third Party Imports
import styled from 'styled-components'

// Table Component
export const StyledTable = styled.table`
	width: 100%;
	color: ${({ theme }) => theme.pallette.text.black75};
	border-collapse: collapse;
	background-color: ${({ theme }) => theme.pallette.geniiWhite};

	& p {
		white-space: nowrap;
	}
`

// Table cell
export const StyledTd = styled.td<{
	$width?: string
	$checkboxSelection: boolean
}>`
	width: ${({ $width }) => $width ?? 'initial'};
	cursor: default;
	padding: 1rem 1.5rem;

	${({ $checkboxSelection }) =>
		$checkboxSelection &&
		`
    &:first-child {
      padding: 1rem 0 1rem 1.5rem;
    }
  `}
`

// Table cell inner
export const TdInner = styled.div<{ $center?: boolean }>`
	display: flex;
	align-items: center;
	${({ $center }) => $center === true && 'justify-content: center;'}
`

// Table head wrapper
export const StyledTHead = styled.thead`
	background-color: ${({ theme }) => theme.pallette.black505};
`

// Table head cell
export const StyledTh = styled.th<{ $checkboxSelection: boolean }>`
	cursor: default;
	padding: 0.75rem 1.5rem;
	font-size: 0.75rem;
	text-align: left;
	font-weight: 600;

	${({ $checkboxSelection = false }) =>
		$checkboxSelection &&
		`
    &:first-child {
      padding: 1rem 0 1rem 1.5rem;
    }
  `}
`

// Table row
export const StyledTr = styled.tr`
	border-bottom: 1px solid #e1dedb;
`

// Outer table container
export const Container = styled.div`
	width: 100%;
	overflow: hidden;
`

// Inner table container
export const ScrollContainer = styled.div`
	max-width: 100%;
	overflow-x: auto;
`

export const StyledTBody = styled.tbody``
