// ** Root Slice & Helper Functions
import { TAGS, baseApi, customErrorHandler } from '@/shared/api'

// ** Type Imports
import { type UserDTO } from '..'

export const getCurrentUserApi = baseApi.injectEndpoints({
	endpoints: (build) => ({
		getCurrentUser: build.query<UserDTO, void>({
			query: () => '/users/me',
			transformErrorResponse: customErrorHandler,
			providesTags: [TAGS.ME]
		})
	})
})

export const { useGetCurrentUserQuery } = getCurrentUserApi
