// ** Redux Toolkit Import
import { configureStore } from '@reduxjs/toolkit'

// ** Third Party Imports
import * as Sentry from '@sentry/react'

// ** Slice & Action Imports
import { baseApi } from '@/shared/api'
import { layoutReducer } from '@/entities/layout'
import { sessionReducer } from '@/entities/session'
import { currentUserReducer } from '@/entities/user'
import { loginReducer } from '@/features/auth/login'

// ** Helper Functions
import { errorMiddleware } from './errorMiddleware'

// User to capture Redux state data
const sentryReduxEnhancer = Sentry.createReduxEnhancer()

export const store = configureStore({
	reducer: {
		[baseApi.reducerPath]: baseApi.reducer,
		session: sessionReducer,
		currentUser: currentUserReducer,
		layout: layoutReducer,
		login: loginReducer
	},
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware().concat(baseApi.middleware, errorMiddleware),
	enhancers: (defaultEnhancers) => {
		return defaultEnhancers.concat(sentryReduxEnhancer)
	}
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
