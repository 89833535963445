import { useMemo } from 'react'
import ReactApexcharts from 'react-apexcharts'
import { Typography } from '@/shared/ui'
import { generateLighterShades } from '@/shared/lib'
import { themeConfig } from '@/app/config/theme'
import { Legend, LegendItem, LegendMarker, RadialChartWrapper } from './styles'
import { getOptions, getSeries, getIsEmpty } from './helpers'

export interface SeriesDTO {
	value: number
	label: string
}

interface IProps {
	type: 'pie' | 'donut'
	height?: number | string
	legend?: boolean
	currency?: boolean
	seriesData: SeriesDTO[]
	themeColor?: string
}

const emptyChartColor = themeConfig.pallette.primary.greenBackground60

export const RadialChart = ({
	type,
	height,
	legend = false,
	currency = false,
	seriesData,
	themeColor = themeConfig.pallette.primary.green
}: IProps) => {
	// Check to see if every series value is 0
	const isEmpty = useMemo(() => getIsEmpty(seriesData), [seriesData])

	const colors = useMemo(() => {
		// Return a light gray color if all values are 0
		if (isEmpty) return [emptyChartColor]
		return generateLighterShades(themeColor, seriesData.length)
	}, [themeColor, seriesData, isEmpty])

	const options = useMemo(
		() => getOptions(seriesData, colors, currency),
		[seriesData, colors, currency]
	)

	const series = useMemo(() => getSeries(seriesData), [seriesData])

	return (
		<RadialChartWrapper>
			<ReactApexcharts
				options={options}
				series={series}
				type={type}
				height={height}
			/>

			{legend && (
				<Legend>
					{seriesData.map((series, i) => (
						<LegendItem key={i}>
							<LegendMarker color={colors[i] || emptyChartColor} />
							<Typography variant='body2' weight='regular'>
								{series.label}
							</Typography>
						</LegendItem>
					))}
				</Legend>
			)}
		</RadialChartWrapper>
	)
}
