// ** Third Party Imports
import styled from 'styled-components'

// ** Component Imports
import { Heading } from '../Heading'

export const ErrorMessageWrapper = styled.div`
	display: flex;
	padding: 5rem;
	align-items: center;
	justify-content: center;
`

export const ErrorMessage = styled(Heading)`
	color: ${({ theme }) => theme.pallette.error.main};
`
