// ** Third Party Imports
import * as Accordion from '@radix-ui/react-accordion'
import styled, { css } from 'styled-components'

interface IProps {
	color?: 'primary' | 'secondary'
	children: React.ReactNode
	isError?: boolean
	className?: string
}

export const AccordionContent = ({
	color = 'primary',
	isError,
	children,
	className
}: IProps) => {
	return (
		<StyledContent $color={color} $isError={isError} className={className}>
			{children}
		</StyledContent>
	)
}

export const StyledContent = styled(Accordion.Content)<{
	$color?: 'primary' | 'secondary'
	$isError?: boolean
}>`
	padding: 1rem;
	overflow: hidden;
	transition: all 300ms ease-in-out;
	border-width: 1px;
	border-style: solid;
	border-bottom-left-radius: 0.5rem;
	border-bottom-right-radius: 0.5rem;

	${({ $color, theme }) =>
		`border-color: ${
			$color === 'primary'
				? theme.pallette.primary.greenBackground
				: theme.pallette.secondary.yellow
		}
		};`}
	${({ $color }) =>
		$color === 'secondary' &&
		` border-top: none;
    `}

	${({ $isError }) =>
		$isError &&
		css`
			border-left: none;
			border-bottom: none;
			border-right: none;
		`}
`
