// ** Third Party Imports
import styled from 'styled-components'

// ** Component Imports
import { Button, Dialog, FormWrapper, Heading, Typography } from '@/shared/ui'

// ** Utility Imports
import { media } from '@/shared/styles'

export const TriggerButton = styled(Button)`
	color: ${({ theme }) => theme.pallette.primary.main};
	border: 1px solid ${({ theme }) => theme.pallette.primary.main};
	background-color: ${({ theme }) => theme.pallette.geniiWhite};
`

export const Content = styled(Dialog.Content)`
	max-width: 43.75rem;
`

export const Form = styled(FormWrapper)`
	gap: 1rem;
	display: flex;
	flex-direction: column;

	& div {
		margin: 0;
	}
`

export const FieldsWrapper = styled.div`
	gap: 1rem;
	display: flex;

	& > div {
		width: 100%;
	}

	${media.sm} {
		flex-wrap: wrap;
	}
`

export const Title = styled(Heading)`
	color: ${({ theme }) => theme.pallette.text.black75};
	margin-bottom: 1rem;
`

export const Description = styled(Typography)`
	color: ${({ theme }) => theme.pallette.text.black50};
	margin-bottom: 1.5rem;
`

export const ButtonsWrapper = styled.div`
	gap: 1rem;
	display: flex;

	${media.xs} {
		flex-wrap: wrap;
	}
`

export const LoaderWrapper = styled.div`
	display: flex;
	padding: 1rem;
	align-items: center;
	justify-content: center;
`
