// ** React Imports
import { forwardRef } from 'react'

// ** Component & Type Imports
import { InputField, type InputFieldProps } from '../InputField'

// ** Utility Imports
import {
	capitalizeFirstLetter,
	capitalizeFirstLetterOfEachWord
} from '@/shared/lib'

interface IProps extends InputFieldProps {
	capitalizeEachWord?: boolean
}

export const CapitalizedInputField = forwardRef<HTMLInputElement, IProps>(
	({ onChange, capitalizeEachWord = false, ...props }, ref) => {
		const handleCapitalizedChange = (
			e: React.ChangeEvent<HTMLInputElement> | string | undefined | null
		) => {
			if (typeof e === 'string' && !capitalizeEachWord)
				onChange?.(capitalizeFirstLetter(e))
			if (typeof e === 'string' && capitalizeEachWord)
				onChange?.(capitalizeFirstLetterOfEachWord(e))

			if (e !== null && typeof e === 'object' && !capitalizeEachWord) {
				e.target.value = capitalizeFirstLetter(e.target.value)
				onChange?.(e)
			}
			if (e !== null && typeof e === 'object' && capitalizeEachWord) {
				e.target.value = capitalizeFirstLetterOfEachWord(e.target.value)
				onChange?.(e)
			}
		}

		return (
			<InputField ref={ref} onChange={handleCapitalizedChange} {...props} />
		)
	}
)
