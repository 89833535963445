// ** Component Imports
import { Heading } from '../Heading'

// ** Styled Components
import { StyledNavLink } from './styles'

interface IProps {
	to: string
	icon?: JSX.Element
	label: string
	onClick?: () => void
}

export const NavLink = ({ to, icon, label, onClick }: IProps) => {
	return (
		<StyledNavLink to={to} onClick={onClick}>
			{icon !== undefined && icon}
			<Heading variant='h4' weight='regular'>
				{label}
			</Heading>
		</StyledNavLink>
	)
}
