// ** Third Party Imports
import styled from 'styled-components'
import * as AvatarPrimitive from '@radix-ui/react-avatar'

interface AvatarProps {
	$width: string
	$height: string
}

export const AvatarRoot = styled(AvatarPrimitive.Root)<AvatarProps>`
	width: ${(props) => props.$width};
	height: ${(props) => props.$height};
	display: flex;
	overflow: hidden;
	flex-shrink: 0;
	align-items: center;
	user-select: none;
	border-radius: 100%;
	justify-content: center;
	vertical-align: middle;
`

export const AvatarImage = styled(AvatarPrimitive.Image)`
	width: 100%;
	height: 100%;
	object-fit: cover;
	border-radius: inherit;
`

export const AvatarFallback = styled(AvatarPrimitive.Fallback)`
	color: ${({ theme }) => theme.pallette.text.black};
	width: 100%;
	height: 100%;
	display: flex;
	font-size: 1rem;
	line-height: 1;
	font-weight: 500;
	align-items: center;
	justify-content: center;
	background-color: ${({ theme }) => theme.pallette.geniiWhite};
`
