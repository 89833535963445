// ** React Imports
import { Suspense } from 'react'

// ** Third Party Imports
import { Outlet } from 'react-router-dom'

// ** Widget Imports
import { Navbar } from '@/widgets/navbar'
import { Footer } from '@/widgets/footer'

// ** Component Imports
import { FallbackLoader } from '@/shared/ui'

export const LandingPageLayout = () => {
	return (
		<>
			<Navbar />
			<Suspense fallback={<FallbackLoader />}>
				<Outlet />
			</Suspense>
			<Footer />
		</>
	)
}
