// ** Third Party Imports
import dayjs from 'dayjs'
import Select from 'react-select'
import styled from 'styled-components'

// ** Component Imports
import { IconButton } from '@/shared/ui'

// ** Utility Imports
import { generateYearsBetween } from '@/shared/lib'

// ** Icon Imports
import { ChevronLeft, ChevronRight } from '@/shared/ui/icons'

interface IProps {
	date: Date
	minDate: dayjs.Dayjs
	maxDate: dayjs.Dayjs
	changeYear: (year: number) => void
	changeMonth: (month: number) => void
	decreaseMonth: () => void
	increaseMonth: () => void
	prevMonthButtonDisabled: boolean
	nextMonthButtonDisabled: boolean
}

const months = [
	'January',
	'February',
	'March',
	'April',
	'May',
	'June',
	'July',
	'August',
	'September',
	'October',
	'November',
	'December'
]

export const DatePickerHeader = ({
	date,
	minDate,
	maxDate,
	changeYear,
	changeMonth,
	decreaseMonth,
	increaseMonth,
	prevMonthButtonDisabled,
	nextMonthButtonDisabled
}: IProps) => {
	// ** Variables
	// Array of year between provided ranges
	const yearsRange = generateYearsBetween(
		minDate.get('year'),
		maxDate.get('year')
	)

	const yearOptions = yearsRange.map((year, index) => ({
		id: index,
		year
	}))

	const monthOptions = months.map((month, index) => ({
		id: index,
		month
	}))

	// Change year dropdown handler
	const yearChange = (newValue: any, actionMeta: any) => {
		changeYear(newValue.year)
	}

	// Change month dropdown handler
	const monthChange = (newValue: any, actionMeta: any) => {
		const selectedMonth = months.indexOf(newValue.month)
		changeMonth(selectedMonth)
	}

	// Handle prev month button click
	const prevMonth = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		e.preventDefault()
		decreaseMonth()
	}

	// Handle next month button click
	const nextMonth = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		e.preventDefault()
		increaseMonth()
	}

	const customStyles = {
		input: (base: any) => ({
			...base,
			color: '#474D65',
			fontFamily: 'inherit'
		}),
		control: (base: any) => ({
			...base,
			borderColor: '#474D65',
			boxShadow: 'none',
			backgroundColor: '#FAFAFA'
		}),
		option: (
			base: any,
			{
				isFocused,
				isSelected
			}: { data: any; isDisabled: any; isFocused: any; isSelected: any }
		) => ({
			...base,
			color: isSelected | isFocused ? '#FAFAFA' : '#474D65',
			backgroundColor: isSelected | isFocused ? '#096A63' : '#FAFAFA'
		}),
		indicatorSeparator: (base: any) => ({
			...base,
			display: 'none'
		})
	}

	return (
		<HeaderWrapper>
			<SelectWrapper>
				<Select
					styles={customStyles}
					value={{ year: dayjs(date).get('year') }}
					onChange={yearChange}
					options={yearOptions}
					getOptionLabel={(option) => option.year.toString()}
					getOptionValue={(option) => option.year.toString()}
				/>

				<Select
					styles={customStyles}
					value={{ month: months[dayjs(date).get('month')] }}
					onChange={monthChange}
					options={monthOptions}
					getOptionLabel={(option) => option.month.toString()}
					getOptionValue={(option) => option.month.toString()}
				/>
			</SelectWrapper>

			<ButtonWrapper>
				<IconButton
					icon={<ChevronLeft />}
					width={1.25}
					height={1.25}
					disabled={prevMonthButtonDisabled}
					ariaLabel='Decrease Month'
					onClick={prevMonth}
				/>

				<IconButton
					icon={<ChevronRight />}
					width={1.25}
					height={1.25}
					disabled={nextMonthButtonDisabled}
					ariaLabel='Increase Month'
					onClick={nextMonth}
				/>
			</ButtonWrapper>
		</HeaderWrapper>
	)
}

const HeaderWrapper = styled.div`
	display: flex;
	padding: 0.5rem 1rem;
	align-items: center;
	justify-content: space-between;
`

const SelectWrapper = styled.div`
	display: flex;
	column-gap: 0.5rem;
	font-family: inherit;
`

const ButtonWrapper = styled.div`
	display: flex;
`
