// ** React Imports
import { forwardRef } from 'react'

// ** Third Party Imports
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

// ** Icon Imports
import { ChevronDown } from '../../icons'

// ** Styled Components
import { YearPickerWrapper } from './styles'

export interface IProps {
	isError?: boolean
	fullWidth?: boolean
	startDate: Date | null
	className?: string
	onChange: (
		date: Date | null,
		event: React.SyntheticEvent<any, Event> | undefined
	) => void
}

// Custom input field for react datepicker
const CustomInput = forwardRef<HTMLInputElement>((props, ref) => {
	return (
		<div className='custom-year-input'>
			<input id='year-picker' {...props} ref={ref} placeholder=' ' />
			<label {...props} htmlFor='year-picker'>
				Year
			</label>
			<ChevronDown {...props} />
		</div>
	)
})

export const YearPicker = ({
	isError = false,
	fullWidth = false,
	startDate,
	className,
	onChange
}: IProps) => {
	return (
		<YearPickerWrapper
			$isError={isError}
			className={className}
			startDate={startDate}
			$fullWidth={fullWidth}
		>
			<DatePicker
				portalId='year-picker-portal-root'
				selected={startDate}
				onChange={onChange}
				showYearPicker
				dateFormat='yyyy'
				yearItemNumber={9}
				renderCustomHeader={() => ''}
				popperPlacement='bottom-end'
				customInput={<CustomInput />}
			/>
		</YearPickerWrapper>
	)
}
