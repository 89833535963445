// ** Third Party Imports
import styled from 'styled-components'

export const BarChartWrapper = styled.div`
	/* Custom tooltip styling */
	& .apexcharts-tooltip.client-management-bar-chart {
		border: none;
		background: ${({ theme }) => theme.pallette.geniiWhite};
		border-radius: 0.5rem;

		& > div {
			padding: 0.5rem;

			& .apexcharts-tooltip-y-group {
				padding: 0;
			}

			& .apexcharts-tooltip-text-y-value {
				margin-left: 0;
			}

			& .apexcharts-tooltip-text {
				color: ${({ theme }) => theme.pallette.geniiBlack};
				font-size: 1rem;
				line-height: 1;
				font-size: 0.75rem;
				font-weight: 600;
				font-family: inherit;
			}
		}
	}
`
