// ** Third Party Imports
import styled from 'styled-components'

// ** Component Imports
import { Typography, IconButton } from '@/shared/ui'

// * Utility Imports
import { media } from '@/shared/styles'

// Table wrapper
export const Container = styled.div`
	border: 1px solid rgba(171, 212, 210, 0.5);
	box-shadow: 0px 1px 3px 0px rgba(132, 136, 152, 0.15);
	border-radius: 1rem;
`

// Table footer
export const Footer = styled.div`
	padding: 0.75rem 1rem;
	background-color: ${({ theme }) => theme.pallette.black505};
	border-bottom-left-radius: 1rem;
	border-bottom-right-radius: 1rem;

	${media.sm} {
		padding: 1rem;
	}
`

// Table header
export const Header = styled.div`
	gap: 1rem;
	display: flex;
	padding: 1rem 1.5rem;
	flex-wrap: wrap;
	justify-content: space-between;

	${media.sm} {
		& div,
		& input,
		& button {
			width: 100%;
			max-width: unset;
		}
	}
`

// Table header children
export const LeftSlotContainer = styled.div`
	display: flex;
	column-gap: 0.5rem;
	flex-grow: 1;
`

export const RightSlotContainer = styled.div`
	display: flex;
	column-gap: 0.5rem;
	flex-grow: 1;
	justify-content: end;
`

// Table pagination wrapper
export const PaginationContainer = styled.div`
	gap: 1rem;
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: space-between;
`

// Chose page size wrapper
export const PageSizeContainer = styled.div``

// Change page wrapper
export const PageNumberContainer = styled.div`
	display: flex;
	column-gap: 0.5rem;
	align-items: center;
`

// Number of pages
export const PageCount = styled(Typography)`
	margin: 0 1rem;
`

// No table data wrapper
export const NoDataWrapper = styled.div`
	display: flex;
	row-gap: 0.5rem;
	padding: 2rem 3rem;
	max-width: 22rem;
	text-align: center;
	align-items: center;
	flex-direction: column;
	justify-content: center;

	& p {
		white-space: normal;
	}
`

export const NoDataOuter = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
`

export const TableLoaderWrapper = styled.div`
	display: flex;
	padding: 2rem 3rem;
	align-items: center;
	justify-content: center;
`

export const StyledIconButton = styled(IconButton)`
	& svg {
		& path {
			stroke: ${({ theme }) => theme.pallette.text.black};
		}
	}
`
