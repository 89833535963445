// ** Type Imports
import { type TypeOptions } from 'react-toastify'

// ** Icon Imports
import { XIcon } from '../../icons'

// ** Styled Components
import {
	ErrorIcon,
	StyledIconButton,
	StyledToast,
	SuccessIcon,
	WarningIcon
} from './styles'

// Custom toast icon
const renderCustomIcon = ({ type }: { type: TypeOptions }) => {
	switch (type) {
		case 'success':
			return <SuccessIcon />
		case 'error':
			return <ErrorIcon />
		case 'warning':
			return <WarningIcon />
		default:
			return null
	}
}

// Custom close button
const renderCustomCloseButton = ({
	closeToast
}: {
	closeToast: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void
}) => (
	<StyledIconButton
		icon={<XIcon />}
		width={1.25}
		height={1.25}
		onClick={closeToast}
		ariaLabel='Close toast'
	/>
)

// ** React Toast
export const ReactToast = () => {
	return (
		<StyledToast
			icon={renderCustomIcon}
			position='bottom-right'
			autoClose={4000}
			closeButton={renderCustomCloseButton}
			closeOnClick={false}
			progressClassName='custom-toast-progress-bar'
		/>
	)
}
