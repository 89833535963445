export const pxToEm = (px: number): string => `${px / 16}em`

export const media = {
	xs: `@media screen and (max-width: ${pxToEm(425)})`,
	sm: `@media screen and (max-width: ${pxToEm(576)})`,
	md: `@media screen and (max-width: ${pxToEm(768)})`,
	lg: `@media screen and (max-width: ${pxToEm(992)})`,
	xl: `@media screen and (max-width: ${pxToEm(1200)})`,
	xxl: `@media screen and (max-width: ${pxToEm(1400)})`,
	custom(px: number): string {
		return `@media screen and (max-width: ${pxToEm(px)})`
	}
}
