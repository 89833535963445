// ** Third Party Imports
import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'

interface ButtonProps {
	size: string
	color: 'primary' | 'secondary' | 'success' | 'error' | 'warning'
	variant: string
	disabled: boolean
	$fullWidth: boolean
}

const buttonStyles = css<ButtonProps>`
	cursor: pointer;
	display: inline-flex;
	border: none;
	outline: none;
	column-gap: 0.5rem;
	align-items: center;
	border-radius: 0.5rem;
	justify-content: center;

	font-style: normal;
	font-weight: 600;

	&:hover {
		background: #26a199;
	}

	& svg {
		width: 1.5rem;
		height: 1.5rem;

		path {
			stroke: #fff;
		}
	}

	${({ size }) =>
		`padding: ${size === 'small' ? '0.25rem 0.5rem' : '0.5rem 1rem'};`}

	${({ size }) => `font-size: ${size === 'small' ? '0.75rem' : '1rem'};`}

${({ color, variant, theme }) =>
		variant === 'secondary' &&
		`
    color: ${
			color === 'secondary'
				? theme.pallette.text.black75
				: theme.pallette[color].main
		};
    background: transparent;
    border: 1px solid ${theme.pallette[color].main};

    &:hover {
      color: #fff;

      & svg {
        path {
          stroke: #fff;
        }
      }
    }

    & svg {
      path {
        stroke: ${
					color === 'secondary'
						? theme.pallette.text.black75
						: theme.pallette[color].main
				};
      }
    }
`}

${({ color, variant, theme }) =>
		variant === 'primary' &&
		`
  color: ${color === 'secondary' ? theme.pallette.text.black75 : '#fff'};
  background: ${theme.pallette[color].main};

  &:hover {
    color: ${color === 'secondary' ? theme.pallette.text.black75 : '#fff'};

    ${
			color === 'secondary' && `background: ${theme.pallette.secondary.yellow};`
		}

    & svg {
      path {
        stroke: ${color === 'secondary' ? theme.pallette.text.black75 : '#fff'};
      }
    }
  }

  & svg {
    path {
      stroke: ${color === 'secondary' ? theme.pallette.text.black75 : '#fff'};
    }
  }
`}

${({ color, variant, theme }) =>
		variant === 'text' &&
		`
  color: ${theme.pallette[color].main}; 
  background: transparent;
  border: none;

  &:hover {
      color: #fff;

      & svg {
        path {
          stroke: #fff;
        }
      }
    }

    & svg {
      path {
        stroke: ${theme.pallette[color].main};
      }
    }
`}

${({ $fullWidth }) =>
		$fullWidth &&
		`
    width: 100%;
`}

${({ theme, disabled }) =>
		disabled &&
		`
    color: #fff !important;
    background: ${theme.pallette.primary.disabled} !important;
    border-color: transparent !important;
    pointer-events: none !important;
    
  &:hover {
    color: #fff !important;
    cursor: default !important;
    background: ${theme.pallette.primary.disabled} !important;
  }

  & svg {
    path {
      stroke: #fff !important;
    }
  }
  `}
`

export const StyledButton = styled.button<ButtonProps>`
	${buttonStyles}
`

export const StyledLink = styled(Link)<ButtonProps>`
	${buttonStyles}
	text-decoration: none;
`

export const StyledAnchor = styled.a<ButtonProps>`
	${buttonStyles}
	text-decoration: none;
`
