// ** Third Party Imports
import styled from 'styled-components'
import { NavLink } from 'react-router-dom'

export const StyledNavLink = styled(NavLink)`
	gap: 0.625rem;
	display: flex;
	flex-wrap: nowrap;
	align-items: center;

	& > h4 {
		color: ${({ theme }) => theme.pallette.geniiWhite};
		white-space: nowrap;
	}

	& > svg {
		& path {
			stroke: ${({ theme }) => theme.pallette.geniiWhite};
		}
	}

	&.active {
		& > h4 {
			color: ${({ theme }) => theme.pallette.primary.greenBackground};
			font-weight: 700;
		}

		& > svg {
			& path {
				stroke: ${({ theme }) => theme.pallette.primary.greenBackground};
				stroke-width: 2.5px;
			}
		}
	}
`
