// ** Third Party Imports
import styled from 'styled-components'

// ** Component Imports
import { Typography } from '../../elements/Typography'

// ** Icon Imports
import { Dash } from '../../icons'

interface Props {
	chip: string
	onRemove?: (chip: string) => void
}

const DashIcon = styled(Dash)`
	cursor: pointer;
	path {
		fill: ${({ theme }) => theme.pallette.text.black75};
	}
`

const Container = styled.div`
	background-color: ${({ theme }) => theme.pallette.primary.greenBackground};
	display: inline-flex;
	column-gap: 0.25rem;
	border-radius: 9999px;
	padding: 0 0.3rem;
`

const StyledTypography = styled(Typography)`
	color: ${({ theme }) => theme.pallette.text.black75};
`

export const InputChip = ({ chip, onRemove }: Props) => {
	const handleClick = (): void => {
		onRemove?.(chip)
	}

	return (
		<Container>
			<StyledTypography variant='body2' weight='semi-bold'>
				{chip}
			</StyledTypography>
			<DashIcon role='button' aria-label='Remove' onClick={handleClick} />
		</Container>
	)
}
