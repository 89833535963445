// ** React Imports
import {
	forwardRef,
	type HTMLInputTypeAttribute,
	useState,
	useEffect
} from 'react'

// ** Third Party Imports
import * as Form from '@radix-ui/react-form'
import { type FieldError, type UseFormTrigger } from 'react-hook-form'

// ** Styled Components
import {
	FormLabelStyled,
	InputFieldStyled,
	CurrencyInputStyled
} from './styles'

export interface InputFieldProps {
	name: string
	type?: HTMLInputTypeAttribute
	label?: string
	value?: string | number | null
	/**
	 * When passing a currency prefix, add a space after the prefix to ensure the prefix is not
	 * concatenated with the value. For example, if the prefix is '$ ' and the value is '-100', the
	 * input field will display '$ -100', but if the prefix is '$' and the value is '-100', the input
	 * field will display '$-100'.
	 */
	prefix?: string
	error?: FieldError | undefined
	isError?: boolean
	disabled?: boolean
	onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void
	onChange?: (
		e: React.ChangeEvent<HTMLInputElement> | string | undefined | null
	) => void
	onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void
	fullWidth?: boolean
	className?: string
	endIcon?: JSX.Element
	isSuccess?: boolean
	startIcon?: JSX.Element
	placeholder?: string
	defaultValue?: string
	trigger?: UseFormTrigger<any>
	autoComplete?: string
}

export const InputField = forwardRef<HTMLInputElement, InputFieldProps>(
	(
		{
			name,
			type = 'text',
			label,
			value,
			prefix,
			error,
			isError = false,
			disabled,
			onBlur,
			onChange,
			onKeyDown,
			fullWidth = false,
			endIcon,
			isSuccess,
			startIcon,
			className,
			placeholder,
			defaultValue,
			trigger,
			autoComplete
		},
		ref
	) => {
		const [valid, setValid] = useState(false)

		const handleBlur = async (e: React.FocusEvent<HTMLInputElement>) => {
			const valid = await trigger?.(name)

			setValid(
				value === '' || value === undefined || value === null
					? false
					: valid ?? false
			)

			onBlur?.(e)
		}

		useEffect(() => {
			if (!error) return
			if (error !== undefined) setValid(false)
		}, [error])

		useEffect(() => {
			if (
				value !== '' &&
				value !== null &&
				value !== undefined &&
				error === undefined
			)
				setValid(true)
		}, [value])

		const handleChange = async (
			e: React.ChangeEvent<HTMLInputElement> | string | undefined
		) => {
			let newVal: string | null = null

			if (!e) newVal = null
			if (typeof e === 'string') newVal = e
			if (e && typeof e !== 'string') newVal = e?.target.value

			onChange?.(newVal)

			if (e === '') setValid(false)
			if (e !== undefined && typeof e !== 'string' && e.target.value === '')
				setValid(false)
			if (error !== undefined) {
				const valid = await trigger?.(name)
				setValid(valid ?? false)
			}
		}

		return (
			<>
				<Form.Control asChild>
					{prefix !== undefined ? (
						<CurrencyInputStyled
							ref={ref}
							name={name}
							type={type}
							value={
								value !== null && value !== undefined && value !== ''
									? Math.abs(parseFloat(value as string))
									: undefined
							}
							prefix={
								value !== '' &&
								value !== null &&
								value !== undefined &&
								parseFloat(value as string) < 0
									? `${prefix}-`
									: prefix
							}
							error={error}
							$isError={isError}
							disabled={disabled}
							onValueChange={handleChange}
							onKeyDown={onKeyDown}
							$fullWidth={fullWidth}
							className={className}
							$startIcon={startIcon}
							$isSuccess={valid || isSuccess}
							placeholder={placeholder ?? ' '}
							defaultValue={defaultValue}
							onBlur={handleBlur}
							autoComplete={autoComplete}
						/>
					) : (
						<InputFieldStyled
							ref={ref}
							name={name}
							type={type}
							value={value !== null ? value : undefined}
							error={error}
							$isError={isError}
							disabled={disabled}
							onChange={handleChange}
							onKeyDown={onKeyDown}
							$fullWidth={fullWidth}
							className={className}
							$startIcon={startIcon}
							$isSuccess={valid || isSuccess}
							placeholder={placeholder ?? ' '}
							defaultValue={defaultValue}
							onBlur={handleBlur}
							autoComplete={autoComplete}
						/>
					)}
				</Form.Control>

				{/* Conditionally render input field start icon */}
				{startIcon !== undefined && startIcon}

				{/* Conditionally render input label */}
				{label !== undefined && (
					<FormLabelStyled $startIcon={startIcon} $isSuccess={isSuccess}>
						{label}
					</FormLabelStyled>
				)}

				{/* Conditionally render input field end icon */}
				{endIcon !== undefined && endIcon}
			</>
		)
	}
)
