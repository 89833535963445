// ** Third Party Imports
import styled from 'styled-components'

export interface InputStylesProps {
	disabled?: boolean
	$shouldLabelFloat?: boolean
	$isError?: boolean
}

export const Container = styled.div<InputStylesProps>`
	appearance: none;
	border-width: 0;
	outline: none;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	&::before,
	&::after {
		box-sizing: border-box;
	}
	&:-webkit-autofill,
	&:-webkit-autofill:hover,
	&:-webkit-autofill:focus,
	&:-webkit-autofill:active {
		transition: background-color 5000s ease-in-out 0s;
	}
	border: 1px solid #848898;
	padding: 0.75rem;
	padding-left: 2.5rem;
	font-size: 0.875rem;
	border-radius: 0.5rem;
	box-shadow: 0px 1px 3px 0px rgba(132, 136, 152, 0.15);
	display: flex;
	flex-wrap: wrap;
	gap: 0.5rem;

	// Floating label on focus

	& input {
		background: none !important;
		border: none !important;
		outline: none !important;
		flex: 1 1 5rem;
		&:focus,
		&:focus-within,
		&:focus-visible {
			outline: none !important;
			border: none !important;
		}
	}

	${({ $shouldLabelFloat, $isError, theme }) =>
		$shouldLabelFloat &&
		`
     border-color: ${
				$isError ? theme.pallette.error.main : theme.pallette.text.black75
			};
     ~ label {
       top: -0.3rem;
       left: 0.75rem;
       color: ${
					$isError ? theme.pallette.error.main : theme.pallette.text.black75
				};
       padding: 0 0.25rem;
       font-size: 0.625rem;
     }

     ~ svg:first-of-type {
       path {
         stroke: ${
						$isError ? theme.pallette.error.main : theme.pallette.text.black75
					};
       }
     }
   }
  `}

	/* & > input {
    &:focus,
    &:not(:placeholder-shown) {
     
  } */

  // Valid styling
  &[data-valid] {
		border-color: ${({ theme }) => theme.pallette.success.main};
		~ label {
			color: ${({ theme }) => theme.pallette.success.main};
		}

		~ svg:first-of-type {
			path {
				stroke: ${({ theme }) => theme.pallette.success.main};
			}
		}
	}

	// Invalid styling
	${({ $isError, theme }) =>
		$isError &&
		`
      border-color: ${theme.pallette.error.main};
      ~ label {
        color: ${theme.pallette.error.main};
      }

      ~ svg:first-of-type {
          path {
            stroke: ${theme.pallette.error.main};
          }
        }
  
  `}

	/* Disabled styling */
${({ disabled }) => disabled === true && `opacity: 0.5;`}
`

export const StyledInput = styled.input`
	background: none !important;
	border: none !important;
	outline: none !important;
	flex: 1 1 5rem;
	&:focus,
	&:focus-within,
	&:focus-visible {
		outline: none !important;
		border: none !important;
	}
`

export const MainContainer = styled.div`
	position: relative;
`

export const FormLabelStyled = styled.label`
	top: 1rem;
	left: 2.5rem;
	color: ${({ theme }) => theme.pallette.text.black50};
	position: absolute;
	font-size: 0.875rem;
	font-weight: 400;
	background-color: ${({ theme }) => theme.pallette.geniiWhite};
	transition: 0.2s ease all;
	line-height: 1;
	cursor: text;
	pointer-events: none;
`

export const StartIconContainer = styled.div<{ $isError: boolean }>`
	position: absolute;
	left: 0.75rem;
	top: 50%;
	transform: translateY(-50%);
	width: 1.25rem;
	height: 1.25rem;
	svg {
		width: 100%;
		height: 100%;
		path {
			stroke: ${({ theme, $isError }) =>
				$isError ? theme.pallette.error.main : theme.pallette.text.black50};
		}
	}
`
