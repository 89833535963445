// ** Third Party Imports
import * as Form from '@radix-ui/react-form'
import CurrencyInput from 'react-currency-input-field'
import styled, { css } from 'styled-components'
import { type FieldError } from 'react-hook-form'

interface InputFieldProps {
	name: string
	error?: FieldError | undefined
	$isError?: boolean
	$startIcon?: JSX.Element
	$fullWidth?: boolean
	disabled?: boolean
	$isSuccess?: boolean
}

interface LabelProps {
	$startIcon?: JSX.Element
	$isSuccess?: boolean
}

export const FormLabelStyled = styled(Form.FormLabel)<LabelProps>`
	top: 1rem;
	${({ $startIcon }) =>
		`left: ${$startIcon !== undefined ? '2.5rem' : '0.75rem'};`}
	color: ${({ theme, $isSuccess }) =>
		$isSuccess ? theme.pallette.success.main : theme.pallette.text.black50};
	position: absolute;
	font-size: 0.875rem;
	font-weight: 400;
	background-color: ${({ theme }) => theme.pallette.geniiWhite};
	transition: 0.2s ease all;
	line-height: 1;
	width: fit-content;
	max-width: 90%;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
`

const InputFieldStyles = css<InputFieldProps>`
	// Reset native styling
	cursor: text;
	appearance: none;
	border-width: 0;
	outline: none;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	&::before {
		box-sizing: border-box;
	}

	&::after {
		box-sizing: border-box;
	}

	// Disable native background on autofill selection
	&:-webkit-autofill,
	&:-webkit-autofill:hover,
	&:-webkit-autofill:focus,
	&:-webkit-autofill:active {
		transition: background-color 5000s ease-in-out 0s;
	}

	// Custom styles
	min-height: 2.875rem;
	border: 1px solid ${({ theme }) => theme.pallette.text.black50};
	// Dynamic padding based on presence of start icon
	padding: 0.75rem;
	${({ $startIcon }) => $startIcon !== undefined && `padding-left: 2.5rem;`}
	font-size: 0.875rem;
	box-shadow: 0px 1px 3px 0px rgba(132, 136, 152, 0.15);
	border-radius: 0.5rem;
	background-color: ${({ theme }) => theme.pallette.geniiWhite};

	&:disabled {
		cursor: default;
	}

	// Floating label on focus
	&:focus,
	&:not(:placeholder-shown),
	&:-webkit-autofill {
		border-color: ${({ theme }) => theme.pallette.text.black75};

		~ label {
			top: -0.3rem;
			left: 0.75rem;
			color: ${({ theme }) => theme.pallette.text.black75};
			padding: 0 0.25rem;
			font-size: 0.625rem;
		}

		~ svg:first-of-type {
			path {
				stroke: ${({ theme }) => theme.pallette.text.black75};
			}
		}
	}

	// Valid styling from react hook form
	${({ theme, $isSuccess }) =>
		$isSuccess &&
		`
      border-color: ${theme.pallette.success.main} !important;

      ~ label {
        color: ${theme.pallette.success.main} !important;
      }
      ~ svg:first-of-type {
        path {
          stroke: ${theme.pallette.success.main} !important;
        }
      }
  `}

	// Invalid styling
	${({ name, error, theme }) =>
		error !== undefined &&
		`
    &[name="${name}"] {
      border-color: ${theme.pallette.error.main} !important;

      ~ label {
        color: ${theme.pallette.error.main} !important;
      }

      ~ svg:first-of-type {
          path {
            stroke: ${theme.pallette.error.main} !important;
          }
        }
    }
  `}

	// Invalid styling
	${({ name, theme, $isError }) =>
		$isError &&
		`
    &[name="${name}"] {
      border-color: ${theme.pallette.error.main} !important;

      ~ label {
        color: ${theme.pallette.error.main} !important;
      }

      ~ svg:first-of-type {
          path {
            stroke: ${theme.pallette.error.main} !important;
          }
        }
    }
  `}

	${({ $fullWidth }) => $fullWidth === true && `width: 100%;`}

  /* Disabled styling */
  ${({ disabled }) => disabled === true && `opacity: 0.5;`}
`

export const InputFieldStyled = styled.input<InputFieldProps>`
	${InputFieldStyles}
`

export const CurrencyInputStyled = styled(CurrencyInput)<InputFieldProps>`
	${InputFieldStyles}
`
