// ** Third Party Imports
import { createSlice } from '@reduxjs/toolkit'

// Action Imports
import { sessionApi } from '../api/sessionApi'
import { loginThunk } from '@/features/auth/login'
import { currentUserThunk } from '@/entities/user'

interface SessionState {
	isAuthenticated: boolean
	isVerifyingToken: boolean
}

const initialState: SessionState = {
	isAuthenticated: false,
	isVerifyingToken: true
}

const sessionSlice = createSlice({
	name: 'session',
	initialState,
	reducers: {
		setIsAuthenticated: (state, action) => {
			state.isAuthenticated = action.payload
		}
	},
	extraReducers: (build) => {
		build.addCase(loginThunk.fulfilled, (state, { payload }) => {
			state.isAuthenticated = !!payload.isAccountActivated
		})
		build.addCase(loginThunk.rejected, (state) => {
			state.isAuthenticated = false
		})
		build.addCase(currentUserThunk.pending, (state) => {
			state.isVerifyingToken = true
		})
		build.addCase(currentUserThunk.fulfilled, (state) => {
			state.isAuthenticated = true
			state.isVerifyingToken = false
		})
		build.addCase(currentUserThunk.rejected, (state) => {
			state.isAuthenticated = false
			state.isVerifyingToken = false
		})
		build.addMatcher(sessionApi.endpoints.logout.matchFulfilled, (state) => {
			state.isAuthenticated = false
		})
	}
})

export const { setIsAuthenticated } = sessionSlice.actions

export const sessionReducer = sessionSlice.reducer
