// ** Root Slice & Helper Functions
import { baseApi, customErrorHandler } from '@/shared/api'

// ** Type Imports
import type {
	ScheduleDemoSchema,
	ScheduleDemoResponseModel
} from '../model/types'

const scheduleDemoApi = baseApi.injectEndpoints({
	endpoints: (build) => ({
		scheduleDemo: build.mutation<ScheduleDemoResponseModel, ScheduleDemoSchema>(
			{
				query: (data) => ({
					url: '/demo-requests',
					method: 'POST',
					body: data
				}),
				transformErrorResponse: customErrorHandler
			}
		)
	})
})

export const { useScheduleDemoMutation } = scheduleDemoApi
