// ** Third Party Imports
import styled from 'styled-components'
import * as Switch from '@radix-ui/react-switch'

export const StyledRoot = styled(Switch.Root)`
	width: 1.25rem;
	height: 0.75rem;
	border: 2px solid ${({ theme }) => theme.pallette.text.black75};
	position: relative;
	transition: all 250ms ease-in-out;
	border-radius: 99999px;
	background-color: ${({ theme }) => theme.pallette.geniiWhite};

	&[data-state='checked'] {
		border-color: ${({ theme }) => theme.pallette.success.main};
	}
`

export const StyledThumb = styled(Switch.Thumb)`
	top: -2px;
	left: -2px;
	width: 0.75rem;
	height: 0.75rem;
	border: 2px solid ${({ theme }) => theme.pallette.text.black75};
	display: block;
	position: absolute;
	transition: all 250ms ease-in-out;
	border-radius: 50%;
	background-color: ${({ theme }) => theme.pallette.geniiWhite};

	&[data-state='checked'] {
		transform: translateX(8px);
		border-color: ${({ theme }) => theme.pallette.success.main};
	}
`
