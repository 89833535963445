// ** Third Party Imports
import * as RadixDialog from '@radix-ui/react-dialog'

// ** Icon Imports
import { XIcon } from '../../icons'

// ** Styled Components
import {
	StyledOverlay,
	StyledContent,
	ContentPosition,
	CloseButton,
	StyledHeading
} from './styles'

interface DialogProps {
	children?: React.ReactNode
	open?: boolean
	onOpenChange?: (open: boolean) => void
}

/**
 * Dialog component
 * @example
 * <Dialog>
 *    <Dialog.Trigger>
 *      <Button>Open Dialog</Button>
 *    </Dialog.Trigger>
 *    <Dialog.Content>
 *      <Dialog.Close />
 *      <Dialog.Title>Title</Dialog.Title>
 *       ...
 *    </Dialog.Content>
 * </Dialog>
 */
export const Dialog = ({ children, ...props }: DialogProps) => {
	return <RadixDialog.Root {...props}>{children}</RadixDialog.Root>
}

Dialog.Trigger = ({ children }: { children?: React.ReactNode }) => {
	return <RadixDialog.Trigger asChild>{children}</RadixDialog.Trigger>
}

Dialog.Content = ({
	children,
	className
}: {
	children?: React.ReactNode
	className?: string
}) => {
	return (
		<RadixDialog.Portal id='radix-dialog-portal'>
			<StyledOverlay />
			<ContentPosition>
				<StyledContent className={className}>{children}</StyledContent>
			</ContentPosition>
		</RadixDialog.Portal>
	)
}

Dialog.Close = ({ children }: { children?: React.ReactNode }) => {
	return (
		<RadixDialog.Close asChild>
			{children ?? (
				<CloseButton
					icon={<XIcon />}
					ariaLabel='Close'
					width={1.5}
					height={1.5}
				/>
			)}
		</RadixDialog.Close>
	)
}

Dialog.Title = ({
	children,
	...props
}: {
	children?: React.ReactNode
	className?: string
}) => {
	return (
		<RadixDialog.Title asChild {...props}>
			<StyledHeading variant='h2' weight='semi-bold'>
				{children}
			</StyledHeading>
		</RadixDialog.Title>
	)
}
