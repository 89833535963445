// ** Third Party Imports
import { createSlice } from '@reduxjs/toolkit'

// ** Action Imports
import { loginThunk } from './loginThunk'

interface LoginState {
	isLoading: boolean
	error: string | null
}

const initialState: LoginState = {
	isLoading: false,
	error: null
}

const loginSlice = createSlice({
	name: 'login',
	initialState,
	reducers: {
		reset(state) {
			state.isLoading = false
			state.error = null
		}
	},
	extraReducers: (builder) => {
		builder.addCase(loginThunk.pending, (state) => {
			state.isLoading = true
			state.error = null
		})
		builder.addCase(loginThunk.fulfilled, (state) => {
			state.isLoading = false
		})
		builder.addCase(loginThunk.rejected, (state, action) => {
			state.isLoading = false
			state.error = action.payload ?? 'error'
		})
	}
})

export const loginReducer = loginSlice.reducer

export const { reset } = loginSlice.actions
