// ** Third Party Imports
import styled from 'styled-components'
import * as RadioGroup from '@radix-ui/react-radio-group'

export const RadioButtonWrapper = styled.div`
	display: flex;
	align-items: center;
`

export const RadioButtonItem = styled(RadioGroup.Item)<{
	$size: 'sm' | 'lg'
}>`
	all: unset;
	width: 1.5rem;
	height: 1.5rem;
	border: 2px solid ${({ theme }) => theme.pallette.text.black75};
	cursor: default;
	border-radius: 100%;

	&[data-state='checked'] {
		border-color: ${({ theme }) => theme.pallette.success.main};
	}

	${({ $size }) =>
		$size === 'sm' &&
		`
    width: 1.25rem;
    height: 1.25rem;
  `}
`

export const RadioButtonIndicator = styled(RadioGroup.Indicator)<{
	$size: 'sm' | 'lg'
}>`
	svg {
		width: 1.5rem;
		height: 1.5rem;

		path {
			stroke: ${({ theme }) => theme.pallette.success.main};
		}
	}

	${({ $size }) =>
		$size === 'sm' &&
		`
    svg {
      width: 1.25rem;
      height: 1.25rem;
    }
  `}
`

export const RadioButtonLabel = styled.label`
	color: #091231;
	font-size: 1rem;
	font-weight: 400;
	font-family: inherit;
	margin-left: 0.5rem;
`
