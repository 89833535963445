// ** RTK Query Imports
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

// ** Environment Variables
import { env } from '../lib'
import { TAGS } from './tags'

export const baseApi = createApi({
	baseQuery: fetchBaseQuery({
		baseUrl: env.apiUrl,
		credentials: 'include'
	}),
	tagTypes: Object.values(TAGS),
	endpoints: () => ({})
})
