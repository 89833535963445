// ** Third Party Imports
import styled, { css } from 'styled-components'
import * as Accordion from '@radix-ui/react-accordion'

export const AccordionItem = styled(Accordion.Item)<{ $isError?: boolean }>`
	overflow: hidden;
	border-radius: 0.5rem;

	&:focus-within {
		z-index: 1;
		position: relative;
	}

	${({ $isError, theme }) =>
		$isError &&
		css`
			border: 1px solid ${theme.pallette.error.main};
		`}
`
