// Generate array of years between two provided values
export const generateYearsBetween = (startYear: number, endYear: number) => {
	const endDate = endYear || new Date().getFullYear()
	const years = []

	for (let i = startYear; i <= endDate; i++) {
		years.push(startYear)
		startYear++
	}

	return years
}

// Format date to international ISO
export const createUTCdateForISO = (date: Date) => {
	const offset = new Date().getTimezoneOffset()
	const myDate = date.getTime() - offset * 60 * 1000
	const dateAsISO = new Date(myDate).toISOString()

	return dateAsISO
}

export const createSearchParamsString = (
	params?: Record<string, string | number | undefined>
): string => {
	if (!params || Object.keys(params).length === 0) {
		return ''
	}

	const searchParams = new URLSearchParams()

	for (const key in params) {
		if (Object.prototype.hasOwnProperty.call(params, key)) {
			searchParams.append(key, params[key]?.toString() ?? '')
		}
	}

	return `?${searchParams.toString()}`
}

// ** Returns initials from string
export const getInitials = (string: string) =>
	string
		.split(/\s/)
		.reduce((response, word) => (response += word.slice(0, 1)), '')

// ** Returns full name from first and last name
type NameType = string | undefined | null
export const getFullName = (firstName: NameType, lastName: NameType) => {
	const first =
		firstName !== '' && firstName !== null && firstName !== undefined
			? firstName
			: null
	const last =
		lastName !== '' && lastName !== null && lastName !== undefined
			? lastName
			: null
	const fullName =
		first !== null && last !== null ? `${first} ${last}` : first ?? last ?? '-'

	return fullName
}

export const capitalizeFirstLetter = (string: string) => {
	return string.charAt(0).toUpperCase() + string.slice(1)
}

export const capitalizeUpperCaseWords = (string: string) => {
	return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()
}

export const capitalizeFirstLetterOfEachWord = (string: string) => {
	return string.replace(/\b[a-z]/g, (char) => char.toUpperCase())
}

// ** Currency formatter
export const currencyFormatter = (
	value: number | undefined,
	currency?: string
) => {
	const formatter = new Intl.NumberFormat('en-US', {
		style: 'currency',
		currency: currency ?? 'USD',
		minimumSignificantDigits: 1
	})

	return (
		formatter
			.format(value ?? 0)
			// if the price begins with digit, place the space after the digit
			.replace(/^([\d,.]+)/, '$1 ')
			// if the price ends with digit, place the space before the digit
			.replace(/([\d,.]+)$/, ' $1')
	)
}

// ** Currency Formatter for negative values
export const currencyFormatterNegative = (
	value: number | undefined,
	round?: boolean,
	prefix: string = '$'
) => {
	let val = value

	if (val === undefined) {
		return ''
	}

	if (round) {
		val = roundNumber(val)
	}

	return `${prefix} ` + val.toLocaleString('en-US')
}

// ** Adds dot at the end of the string if it doesn't have one
export const addDotAtEnd = (str: string): string => {
	return str.endsWith('.') ? str : `${str}.`
}

/**
 * Check if provided date is valid
 * @see https://stackoverflow.com/questions/1353684/detecting-an-invalid-date-date-instance-in-javascript
 */
export const isDateValid = (date: Date) => {
	return date instanceof Date && !isNaN(date.getTime())
}

// Round number to the nearest integer
export const roundNumber = (num: number) => {
	return Math.round(num)
}
