// ** Third Party Imports
import styled from 'styled-components'

export const CheckboxRoot = styled.div`
	display: flex;
	align-items: center;
`

export const StyledInput = styled.input`
	position: relative;
	border: 2px solid ${({ theme }) => theme.pallette.text.black75};
	border-radius: 4px;

	background: none;
	cursor: pointer;
	line-height: 0;
	outline: 0;
	padding: 0 !important;
	vertical-align: text-top;
	height: 1.25rem;
	width: 1.25rem;
	-webkit-appearance: none;

	&:checked {
		border: 2px solid ${({ theme }) => theme.pallette.success.second};
		&:before {
			content: '';
			position: absolute;
			right: 50%;
			top: 50%;
			width: 3px;
			height: 6px;
			border: solid ${({ theme }) => theme.pallette.success.second};
			border-width: 0 2px 2px 0;
			margin: 0px -1px 2px 0px;
			transform: rotate(45deg) translate(-50%, -50%);
			z-index: 2;
		}
	}
`

export const CheckboxLabel = styled.label`
	color: ${({ theme }) => theme.pallette.text.black};
	font-size: 1rem;
	font-weight: 400;
`

export const TextContainer = styled.div`
	margin-left: 0.5rem;
	display: flex;
	flex-direction: column;
`
