import styled from 'styled-components'

export const FieldWrapper = styled.div`
	position: relative;
	width: 100%;
	max-width: 20rem;

	/* Start icon styling */
	& svg {
		top: 0.75rem;
		left: 0.75rem;
		width: 1.25rem;
		height: 1.25rem;
		position: absolute;
	}

	/* Floating label styling */
	& label {
		top: 0.75rem;
		left: 2.5rem;
		color: ${({ theme }) => theme.pallette.text.black50};
		position: absolute;
		font-size: 0.857rem;
		background: ${({ theme }) => theme.pallette.geniiWhite};
		transition: 0.2s ease all;
		font-weight: 400;
		cursor: text;
	}

	/* Input field styling */
	& input {
		width: 100%;
		border: 1px solid ${({ theme }) => theme.pallette.text.black50};
		outline: none;
		padding: 0.75rem;
		font-size: 0.875rem;
		background: ${({ theme }) => theme.pallette.geniiWhite};
		box-shadow: 0px 1px 3px 0px rgba(132, 136, 152, 0.15);
		padding-left: 2.5rem;
		border-radius: 0.5rem;

		&:focus,
		&:not(:placeholder-shown) {
			border-color: ${({ theme }) => theme.pallette.text.black75};

			~ label {
				top: -0.438rem;
				left: 0.75rem;
				color: ${({ theme }) => theme.pallette.text.black75};
				padding: 0 0.25rem;
				font-size: 0.625rem;
			}
		}
	}
`
