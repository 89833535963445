// ** Third Party Imports
import styled from 'styled-components'
import * as RadixDialog from '@radix-ui/react-dialog'

// ** Component Imports
import { IconButton } from '../IconButton'

import { zIndex } from '@/shared/styles'

export const StyledOverlay = styled(RadixDialog.Overlay)`
	inset: 0;
	position: fixed;
	background-color: rgba(0, 0, 0, 0.2);
`

export const StyledContent = styled(RadixDialog.Content)`
	top: 50%;
	left: 50%;
	width: 90vw;
	display: flex;
	row-gap: 1rem;
	padding: 1.5rem;
	position: fixed;
	overflow: hidden;
	transform: translate(-50%, -50%);
	max-width: 30.5rem;
	max-height: 85vh;
	box-shadow: 0px 2px 8px 0px rgba(9, 106, 99, 0.25);
	border-radius: 0.5rem;
	flex-direction: column;
	background-color: ${({ theme }) => theme.pallette.geniiWhite};
	z-index: ${zIndex.dialog};
`

export const StyledTitle = styled(RadixDialog.Title)`
	color: ${({ theme }) => theme.pallette.text.black75};
	font-size: 1.8125rem;
	font-weight: 600;
`

export const StyledDescription = styled(RadixDialog.Description)`
	color: ${({ theme }) => theme.pallette.text.black50};
	font-size: 0.875rem;
	font-weight: 500;
`

export const StyledIconButton = styled(IconButton)`
	top: 0.5rem;
	right: 0.5rem;
	position: absolute;
`
