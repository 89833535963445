// ** Styled Components
import { LoaderWrapper } from './styles'

interface IProps {
	className?: string
}

export const Loader = ({ className }: IProps) => {
	return (
		<LoaderWrapper role='alert' aria-busy='true' className={className}>
			<div></div>
			<div></div>
			<div></div>
			<div></div>
		</LoaderWrapper>
	)
}
