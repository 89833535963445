// ** React Imports
import { type ReactNode } from 'react'

// ** Styled Components
import { HeadingStyled } from './styles'

interface IProps {
	/**
	 * Custom inline styling
	 */
	style?: React.CSSProperties | undefined
	/**
	 * Font weight
	 */
	weight?: 'regular' | 'medium' | 'semi-bold' | 'bold'
	/**
	 * Heading size
	 */
	variant: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'
	/**
	 * Heading content
	 */
	children: ReactNode
	/**
	 * Custom css class
	 */
	className?: string
}

export const Heading = ({
	style,
	weight = 'regular',
	variant,
	children,
	className
}: IProps) => {
	return (
		<HeadingStyled
			as={variant}
			style={style}
			weight={weight}
			variant={variant}
			className={className}
		>
			{children}
		</HeadingStyled>
	)
}
