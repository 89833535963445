// ** Third Party Imports
import styled from 'styled-components'
import * as Dialog from '@radix-ui/react-dialog'

import { zIndex } from '@/shared/styles'

// ** Component Imports
import { Heading } from '../Heading'
import { IconButton } from '../IconButton'

export const StyledOverlay = styled(Dialog.Overlay)`
	inset: 0;
	z-index: ${zIndex.dialogOverlay};
	position: fixed;
	background-color: rgba(0, 0, 0, 0.2);
`

export const StyledContent = styled(Dialog.Content)`
	width: 90vw;
	outline: none;
	max-width: 30.5rem;
	max-height: 85vh;
	padding: 1.5rem;
	overflow-y: auto;
	box-shadow: 0px 2px 8px 0px rgba(9, 106, 99, 0.25);
	border-radius: 0.5rem;
	background-color: ${({ theme }) => theme.pallette.geniiWhite};
	position: relative;
	z-index: ${zIndex.dialog};
`

export const ContentPosition = styled.div`
	top: 50%;
	left: 50%;
	z-index: ${zIndex.dialog};
	position: fixed;
	transform: translate(-50%, -50%);
`

export const CloseButton = styled(IconButton)`
	position: absolute;
	right: 0.25rem;
	top: 0.25rem;
	padding: 0.5rem;
`

export const StyledHeading = styled(Heading)`
	margin-bottom: 2rem;
	color: ${({ theme }) => theme.pallette.text.black75};
`
