// ** Styled Components
import { TypographyStyled } from './styles'

interface IProps {
	/**
	 * Link styling
	 */
	link?: boolean
	/**
	 * Custom inline styling
	 */
	style?: React.CSSProperties | undefined
	/**
	 * Font weight
	 */
	weight?: 'regular' | 'medium' | 'semi-bold' | 'bold'
	/**
	 * Typography size/type
	 */
	variant?: 'body1' | 'body2' | 'body3' | 'caption'
	/**
	 * Typography content
	 */
	children: React.ReactNode
	/**
	 * On text click handler
	 */
	onClick?: () => void
	/**
	 * Custom css class
	 */
	className?: string

	/**
	 * Text truncation
	 */
	textTrunc?: number
	/**
	 * Optional white-space property
	 */
	whiteSpace?:
		| 'normal'
		| 'nowrap'
		| 'pre'
		| 'pre-wrap'
		| 'pre-line'
		| 'break-spaces'
}

export const Typography = ({
	link = false,
	style,
	weight = 'regular',
	variant = 'body1',
	children,
	onClick,
	className,
	textTrunc,
	whiteSpace
}: IProps) => {
	return (
		<TypographyStyled
			link={link}
			style={style}
			weight={weight}
			variant={variant}
			onClick={onClick}
			className={className}
			$textTrunc={textTrunc}
			$whiteSpace={whiteSpace}
		>
			{children}
		</TypographyStyled>
	)
}
