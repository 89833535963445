// ** Third Party Imports
import styled from 'styled-components'
import * as RadixSelect from '@radix-ui/react-select'

// ** Utility Imports
import { zIndex } from '@/shared/styles'

interface TriggerProps {
	$isError: boolean
	$selected: boolean
	$fullWidth: boolean
}

interface LabelProps {
	$selected: boolean
}

interface ContentProps {
	$fullWidth: boolean
}

export const StyledTrigger = styled(RadixSelect.Trigger)<TriggerProps>`
	min-height: 2.875rem;
	border: 1px solid ${({ theme }) => theme.pallette.text.black50};
	outline: none;
	display: flex;
	padding: 0.75rem;
	position: relative;
	font-size: 0.875rem;
	box-shadow: 0px 1px 3px 0px rgba(132, 136, 152, 0.15);
	column-gap: 0.5rem;
	align-items: center;
	border-radius: 0.5rem;
	justify-content: space-between;
	background-color: ${({ theme }) => theme.pallette.geniiWhite};

	${({ $fullWidth }) => $fullWidth && `width: 100%;`}

	&[data-placeholder] {
		color: ${({ theme }) => theme.pallette.text.black50};
	}

	&[data-state='open'] {
		& label {
			top: -1px;
			left: 0.75rem;
			color: ${({ theme }) => theme.pallette.text.black75};
			padding: 0 0.25rem;
			font-size: 0.625rem;
		}
	}

	& svg {
		width: 1rem;
		height: 1rem;
		display: flex;
	}

	/* Valid styling */
	${({ theme, $selected }) =>
		$selected &&
		`
    border-color: ${theme.pallette.success.main};

    & label {
      color: ${theme.pallette.success.main} !important;
    }

    & svg {
      path {
        stroke: ${theme.pallette.success.main} !important;
      }
    }
  `}

	/* Invalid styling */
	${({ $isError, theme }) =>
		$isError &&
		`
    border-color: ${theme.pallette.error.main};

    & label {
      color: ${theme.pallette.error.main} !important;
    }

    & svg {
      path {
        stroke: ${theme.pallette.error.main} !important;
      }
    }
  `}
`

export const StyledLabel = styled.label<LabelProps>`
	top: 50%;
	color: ${({ theme }) => theme.pallette.text.black50};
	position: absolute;
	transform: translateY(-50%);
	font-size: 0.875rem;
	font-weight: 400;
	background: ${({ theme }) => theme.pallette.geniiWhite};
	transition: 0.3s ease all;
	line-height: 1;

	${({ theme, $selected }) =>
		$selected &&
		`
    top: -1px;
    left: 0.75rem;
    color: ${theme.pallette.text.black75};
    padding: 0 0.25rem;
    font-size: 0.625rem;
  `}
`

export const StyledPortal = styled(RadixSelect.Portal)`
	z-index: ${zIndex.selectDropdown};
`

export const StyledContent = styled(RadixSelect.Content)<ContentProps>`
	overflow-x: hidden;
	overflow-y: auto;
	max-height: 350px;
	margin-top: 0.25rem;
	box-shadow: 0px 1px 3px 0px rgba(132, 136, 152, 0.15);
	border-radius: 0.5rem;
	border: 1px solid ${({ theme }) => theme.pallette.text.black50};
	background-color: ${({ theme }) => theme.pallette.geniiWhite};

	${({ $fullWidth }) =>
		$fullWidth && `width: var(--radix-select-trigger-width);`}

	/* Hide dropdown arrow */
	> span {
		display: none;
	}
`

export const StyledItem = styled(RadixSelect.Item)`
	cursor: pointer;
	padding: 0.75rem;
	outline: none;
	display: flex;
	align-items: center;

	/* Check icon wrapper */
	& span:nth-child(2) {
		display: flex;
	}

	/* Check icon styling */
	& svg {
		width: 1rem;
		height: 1rem;
		margin-left: 0.5rem;

		path {
			stroke: ${({ theme }) => theme.pallette.success.main};
		}
	}

	&:not(:last-child) {
		border-bottom: 1px solid ${({ theme }) => theme.pallette.text.black50};
	}

	&:hover {
		background-color: rgba(171, 212, 210, 0.6);
	}
`

export const ScrollUpButton = styled(RadixSelect.ScrollUpButton)`
	display: flex;
	justify-content: center;
`

export const ScrollDownButton = styled(RadixSelect.ScrollDownButton)`
	display: flex;
	justify-content: center;
`
