// ** React Imports
import { useEffect } from 'react'

// ** Third Party Imports
import { useLocation } from 'react-router-dom'

export const ScrollToTop = () => {
	// ** Hooks
	const { pathname } = useLocation()

	// Scroll to top on path change
	useEffect(() => {
		window.scrollTo(0, 0)
	}, [pathname])

	return null
}
