// ** React Imports
import { forwardRef } from 'react'

// ** Third Party Imports
import * as Form from '@radix-ui/react-form'

// ** Styled Components
import { FormLabelStyled, TextAreaStyled } from './styles'

interface IProps {
	name: string
	label?: string
	value?: string | number
	isError?: boolean
	minRows?: number
	maxRows?: number
	onChange?: (
		e: React.ChangeEvent<HTMLTextAreaElement> | string | undefined
	) => void
	isSuccess?: boolean
	fullWidth?: boolean
	className?: string
	endIcon?: JSX.Element
	startIcon?: JSX.Element
	placeholder?: string
}

export const TextAreaField = forwardRef<HTMLTextAreaElement, IProps>(
	(
		{
			name,
			label,
			value,
			isError = false,
			minRows,
			maxRows,
			onChange,
			isSuccess = false,
			fullWidth = false,
			endIcon,
			startIcon,
			className,
			placeholder
		},
		ref
	) => {
		return (
			<>
				<Form.Control asChild>
					<TextAreaStyled
						ref={ref}
						name={name}
						value={value}
						minRows={minRows}
						maxRows={maxRows}
						$isError={isError}
						onChange={onChange}
						$isSuccess={isSuccess}
						$fullWidth={fullWidth}
						className={className}
						$startIcon={startIcon}
						placeholder={placeholder ?? ' '}
					/>
				</Form.Control>

				{/* Conditionally render input field start icon */}
				{startIcon !== undefined && startIcon}

				{/* Conditionally render input label */}
				{label !== undefined && (
					<FormLabelStyled $startIcon={startIcon}>{label}</FormLabelStyled>
				)}

				{/* Conditionally render input field end icon */}
				{endIcon !== undefined && endIcon}
			</>
		)
	}
)
