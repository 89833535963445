// ** Third Party Imports
import styled from 'styled-components'

// ** Component Imports
import { Typography } from '../../elements/Typography'

// ** Icon Imports
import { CheckBroken, Circle } from '../../icons'

export const PasswordErrorWrapper = styled.div`
	margin-top: 0.5rem;
`

export const PasswordErrorItem = styled.div`
	display: flex;
	align-items: center;
	margin-bottom: 0.25rem;

	& > svg {
		margin-right: 0.5rem;
	}
`

export const PasswordErrorMessage = styled(Typography)`
	color: ${({ theme }) => theme.pallette.text.black75};
`

export const CircleIcon = styled(Circle)<{ error: boolean }>`
	width: 0.875rem;
	height: 0.875rem;
	flex-shrink: 0;

	${({ error }) => `
    & path {
      stroke: ${error ? '#091231' : '#7EAD82'};
    }
  `}
`

export const CheckBrokenIcon = styled(CheckBroken)<{ error: boolean }>`
	width: 0.875rem;
	height: 0.875rem;
	flex-shrink: 0;

	${({ error }) => `
  & path {
    stroke: ${error ? '#091231' : '#7EAD82'};
  }
`}
`
