// ** React Imports
import { useEffect } from 'react'

// ** Third Party Imports
import * as yup from 'yup'
import { toast } from 'react-toastify'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { createSearchParams, useNavigate } from 'react-router-dom'

import { printError } from '@/shared/api'
import { useAppDispatch, useAppSelector } from '@/shared/lib/hooks'

// ** Action Imports
import { loginThunk } from '../model/loginThunk'

// ** Type Imports
import { type UserDTO } from '@/entities/user'
import { EMAIL, addDotAtEnd } from '@/shared/lib'

const schema = yup.object().shape({
	email: yup
		.string()
		.matches(EMAIL, 'Email must be a valid email.')
		.required('Email is a required field.'),
	password: yup.string().required('Password is a required field.'),
	terms: yup.boolean()
})

const defaultValues = {
	email: '',
	password: '',
	terms: false
}

export const useLoginCard = () => {
	// ** State

	// ** Hooks
	const navigate = useNavigate()
	const dispatch = useAppDispatch()
	const { error, isLoading } = useAppSelector((state) => state.login)

	useEffect(() => {
		if (
			error !== null &&
			error === "You don't have permission to access this page"
		) {
			toast.error(addDotAtEnd(printError(error)))
		}
	}, [error])

	// React hook form
	const {
		control,
		handleSubmit,
		trigger,
		formState: { errors }
	} = useForm({
		defaultValues,
		mode: 'onChange',
		resolver: yupResolver(schema)
	})

	const onSubmit = (formData: any) => {
		dispatch(
			loginThunk({
				email: formData.email,
				password: formData.password,
				keepMeLoggedIn: formData.terms
			})
		)
			.unwrap()
			.then((data: UserDTO) => {
				// If the user has completed 2FA, redirect him to login,
				// otherwise, redirect him to second step of register flow.
				if (!data.isAccountActivated) {
					navigate({
						pathname: '/register',
						search: createSearchParams({
							step: '2',
							userId: data.id.toString()
						}).toString()
					})
				}
			})
	}

	return {
		// form
		errors,
		control,
		onSubmit,
		handleSubmit,
		// request
		isLoading,
		trigger
	}
}
