import {
	type Middleware,
	type MiddlewareAPI,
	type Dispatch,
	type AnyAction,
	isRejectedWithValue
} from '@reduxjs/toolkit'
import { toast } from 'react-toastify'
import { setIsAuthenticated } from '@/entities/session'
import { setCurrentUser } from '@/entities/user'
import { printError } from '@/shared/api'
import { addDotAtEnd } from '@/shared/lib'

const getIsUnauthorized = (action: any): boolean => {
	const isBaseQuery401 = action.meta.baseQueryMeta?.response?.status === 401
	const isCurrentUserThunkRejected = action.type === 'user/currentUser/rejected'
	const isLoginThunkRejected = action.type === 'authentication/login/rejected'
	return isBaseQuery401 || isCurrentUserThunkRejected || isLoginThunkRejected
}

const handleUnauthorizedUser = (
	action: any,
	next: Dispatch<AnyAction>,
	dispatch: Dispatch<AnyAction>
) => {
	dispatch(setIsAuthenticated(false))
	dispatch(setCurrentUser(null))
	return next(action)
}

const handleUserProfilePhotoError = (
	action: any,
	next: Dispatch<AnyAction>,
	dispatch: Dispatch<AnyAction>
) => {
	toast.error('An error occurred while updating your profile photo.')
	return next(action)
}

export const errorMiddleware: Middleware =
	({ dispatch }: MiddlewareAPI) =>
	(next) =>
	(action) => {
		if (isRejectedWithValue(action)) {
			const isUnauthorized = getIsUnauthorized(action)

			if (isUnauthorized) return handleUnauthorizedUser(action, next, dispatch)
			if (action.meta.arg?.endpointName === 'editUserProfilePhoto')
				return handleUserProfilePhotoError(action, next, dispatch)

			// Prevent toast for verifyInviteToken endpoint
			if (action.meta.arg?.endpointName === 'verifyInviteToken') return

			toast.error(addDotAtEnd(printError(action.payload)))
		}

		return next(action)
	}
