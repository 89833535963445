// ** Third Party Imports
import styled from 'styled-components'

export const RadialChartWrapper = styled.div`
	max-width: 20rem;
	/* Custom tooltip styling */
	& .apexcharts-tooltip.custom-radial-chart-tooltip {
		background: ${({ theme }) => theme.pallette.geniiWhite};
		border-radius: 0.5rem;

		& .tooltip {
			padding: 0.5rem;

			& span {
				color: ${({ theme }) => theme.pallette.geniiBlack};
				font-size: 1rem;
				line-height: 1;
				font-size: 0.75rem;
				font-weight: 600;
				font-family: inherit;
			}
		}
	}
`

export const Legend = styled.div`
	display: flex;
	margin-top: 2rem;
	justify-content: center;
`

export const LegendItem = styled.div`
	display: flex;
	align-items: center;
	&:not(:last-child) {
		margin-right: 0.8rem;
	}

	& p {
		color: ${({ theme }) => theme.pallette.text.black50};
	}
`

export const LegendMarker = styled.span<{ color: string }>`
	width: 0.5rem;
	border: none;
	height: 0.5rem;
	margin-right: 0.5rem;
	border-radius: 50%;
	background-color: ${({ color }) => color};
`
