// ** Styled Components
import { FormMessageStyled } from './styles'

interface IProps {
	/**
	 * Text color
	 */
	color?: 'primary' | 'secondary' | 'success' | 'error' | 'warning' | 'text'
	/**
	 * Text content
	 */
	children: React.ReactNode
}

export const FormHelperText = ({ color = 'text', children }: IProps) => {
	return <FormMessageStyled color={color}>{children}</FormMessageStyled>
}
