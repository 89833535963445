// Registration validation regular expressions
// Match valid phone number
export const PHONE_NUMBER = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/g // eslint-disable-line no-useless-escape

/**
 * Match valid email
 * See test cases:
 * @see https://jsfiddle.net/ghvj4gy9/
 * taken from Chromium:
 * @see https://source.chromium.org/chromium/chromium/src/+/main:third_party/blink/web_tests/fast/forms/resources/ValidityState-typeMismatch-email.js?q=ValidityState-typeMismatch-email.js&ss=chromium
 */
export const EMAIL =
	/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ // eslint-disable-line no-useless-escape

// Match at least one uppercase and one lowercase letter
export const STRING_CASE = /(?=.*[a-z])(?=.*[A-Z])/

// Match repeating string patterns
export const STRING_PATTERN = /^(?!.*(.)\1).{4,10}$/

// Match at least one number and one special character
export const STRING_CHARACTERS_DIGITS = /(?=.*[!@#$%^&*.?])(?=.*\d)/

// Match only letters
export const STRING_LETTERS = /^[aA-zZ\s]+$/
