class ZIndex {
	base = 1
	footer = this.base + 100
	navbar = this.base + 200
	sidebar = this.base + 100
	dialogOverlay = this.base + 100
	dialog = this.base + 1_000
	selectDropdown = this.base + 10_000
}
export const zIndex = new ZIndex()
