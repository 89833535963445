// ** Third Party Imports
import { createSlice } from '@reduxjs/toolkit'

// ** Action Imports
import { sessionApi } from '@/entities/session'
import { loginThunk } from '@/features/auth/login'
import { currentUserThunk } from '../model/currentUserThunk'

// ** Type Imports
import { type UserDTO } from './types'

interface UserState {
	user: UserDTO | null
}

const initialState: UserState = {
	user: null
}

const currentUserSlice = createSlice({
	name: 'user',
	initialState,
	reducers: {
		setCurrentUser: (state, action) => {
			state.user = action.payload
		}
	},
	extraReducers: (build) => {
		build.addCase(loginThunk.fulfilled, (state, { payload }) => {
			state.user = payload || null
		})
		build.addCase(currentUserThunk.fulfilled, (state, { payload }) => {
			state.user = payload || null
		})
		build.addMatcher(sessionApi.endpoints.logout.matchFulfilled, (state) => {
			state.user = null
		})
	}
})

export const { setCurrentUser } = currentUserSlice.actions

export const currentUserReducer = currentUserSlice.reducer
