// ** Third Party Imports
import * as Form from '@radix-ui/react-form'
import styled, { css } from 'styled-components'
import reactTextareaAutosize from 'react-textarea-autosize'

interface TextAreaProps {
	name: string
	$isError?: boolean
	$isSuccess?: boolean
	$startIcon?: JSX.Element
	$fullWidth?: boolean
}

interface LabelProps {
	$startIcon?: JSX.Element
}

export const FormLabelStyled = styled(Form.FormLabel)<LabelProps>`
	top: 1rem;
	${({ $startIcon }) =>
		`left: ${$startIcon !== undefined ? '2.5rem' : '0.75rem'};`}
	color: ${({ theme }) => theme.pallette.text.black50};
	position: absolute;
	font-size: 0.875rem;
	font-weight: 400;
	background-color: ${({ theme }) => theme.pallette.geniiWhite};
	transition: 0.2s ease all;
	line-height: 1;
`

const TextAreaStyles = css<TextAreaProps>`
	// Reset native styling
	resize: none;
	appearance: none;
	border-width: 0;
	outline: none;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	&::before {
		box-sizing: border-box;
	}

	&::after {
		box-sizing: border-box;
	}

	// Disable native background on autofill selection
	&:-webkit-autofill,
	&:-webkit-autofill:hover,
	&:-webkit-autofill:focus,
	&:-webkit-autofill:active {
		transition: background-color 5000s ease-in-out 0s;
	}

	// Custom styles
	border: 1px solid #848898;
	${({ $startIcon }) =>
		`padding: ${
			$startIcon !== undefined ? '0.75rem 0.75rem 0.75rem 2.5rem' : '0.75rem'
		};`}
	font-size: 0.875rem;
	border-radius: 0.5rem;
	box-shadow: 0px 1px 3px 0px rgba(132, 136, 152, 0.15);
	background-color: ${({ theme }) => theme.pallette.geniiWhite};

	// Floating label on focus
	&:focus,
	&:not(:placeholder-shown) {
		border-color: ${({ theme }) => theme.pallette.text.black75};
		~ label {
			top: -0.3rem;
			left: 0.75rem;
			color: ${({ theme }) => theme.pallette.text.black75};
			padding: 0 0.25rem;
			font-size: 0.625rem;
		}

		~ svg:first-of-type {
			path {
				stroke: ${({ theme }) => theme.pallette.text.black75};
			}
		}
	}

	// Valid styling from react hook form
	${({ theme, $isSuccess }) =>
		$isSuccess &&
		`
      border-color: ${theme.pallette.success.main} !important;

      ~ label {
        color: ${theme.pallette.success.main} !important;
      }
      ~ svg:first-of-type {
        path {
          stroke: ${theme.pallette.success.main} !important;
        }
      }
  `}

	// Invalid styling
	${({ name, $isError, theme }) =>
		$isError &&
		`
    &[name="${name}"] {
      border-color: ${theme.pallette.error.main};

      ~ label {
        color: ${theme.pallette.error.main};
      }

      ~ svg:first-of-type {
          path {
            stroke: ${theme.pallette.error.main};
          }
        }
    }
  `}

	${({ $fullWidth }) => $fullWidth === true && `width: 100%;`}
`

export const TextAreaStyled = styled(reactTextareaAutosize)<TextAreaProps>`
	${TextAreaStyles}
`
