// ** Styled Components
import { FormFieldStyled } from './styles'

interface IProps {
	name: string
	children: React.ReactNode
	className?: string
}

export const FormControl = ({ name, children, className }: IProps) => {
	return (
		<FormFieldStyled name={name} className={className}>
			{children}
		</FormFieldStyled>
	)
}
