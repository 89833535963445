// ** Third Party Imports
import styled from 'styled-components'
import * as Form from '@radix-ui/react-form'

interface FormMessageProps {
	color?: 'primary' | 'secondary' | 'success' | 'error' | 'warning' | 'text'
}

export const FormMessageStyled = styled(Form.Message)<FormMessageProps>`
	font-size: 0.75rem;
	font-weight: 400;

	// Text color
	${({ color, theme }) =>
		color !== undefined &&
		`
      color: ${
				color === 'text'
					? theme.pallette[color].black50
					: theme.pallette[color].main
			};
    `}
`
