// ** Component Imports
import { Loader } from '../../index'

// ** Styled Components
import { LoaderWrapper } from './styles'

interface Props {
	fullHeight?: boolean
}

export const FallbackLoader = ({ fullHeight = true }: Props) => {
	return (
		<LoaderWrapper $fullHeight={fullHeight}>
			<Loader />
		</LoaderWrapper>
	)
}
