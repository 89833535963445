import { type FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'

export const customErrorHandler = (err: FetchBaseQueryError) => {
	if (
		typeof err.data === 'object' &&
		Object.prototype.hasOwnProperty.call(err.data, 'message')
	) {
		if (
			typeof (err.data as { message?: string | string[] })?.message === 'string'
		) {
			return (err.data as { message?: string }).message
		}
		if (Array.isArray((err.data as { message?: string | string[] })?.message)) {
			return (
				(err.data as { message: string[] }).message[0] || 'Something went wrong'
			)
		}
	}

	return 'Something went wrong'
}
