// ** Third Party Imports
import { Navigate, type RouteObject } from 'react-router-dom'

// ** Utility Imports
import { lazyImport } from '@/shared/lib'

// ** Component Imports
// Uncomment the lines below after the launch phase
// import { BlankLayout } from '@/shared/ui'

// ** Layout Imports
import { LandingPageLayout } from '@/app/layouts/LandingPageLayout'

// ** Route Imports
// Public Routes
/**
 * Uncomment the lines below after the launch phase
 * This enables only home route to be available during the launch phase
 */
// const { LoginPage } = lazyImport(
//  async () => await import('@/pages/login'),
//  'LoginPage'
// )
// const { RegisterPage } = lazyImport(
//  async () => await import('@/pages/register'),
//  'RegisterPage'
// )
// const { ForgotPasswordPage } = lazyImport(
//  async () => await import('@/pages/forgot-password'),
//  'ForgotPasswordPage'
// )
// const { NewPasswordPage } = lazyImport(
//  async () => await import('@/pages/new-password'),
//  'NewPasswordPage'
// )
// Landing Page Routes
const { HomePage } = lazyImport(
	async () => await import('@/pages/home'),
	'HomePage'
)
const { AboutPage } = lazyImport(
	async () => await import('@/pages/about'),
	'AboutPage'
)
const { FaqPage } = lazyImport(
	async () => await import('@/pages/faq'),
	'FaqPage'
)
const { ContactPage } = lazyImport(
	async () => await import('@/pages/contact'),
	'ContactPage'
)
const { PrivacyPolicyPage } = lazyImport(
	async () => await import('@/pages/privacy-policy'),
	'PrivacyPolicyPage'
)
const { TermsAndConditionsPage } = lazyImport(
	async () => await import('@/pages/terms-and-conditions'),
	'TermsAndConditionsPage'
)

export const publicRoutes: RouteObject[] = [
	/**
	 * Uncomment the lines below after the launch phase
	 * This enables only home route to be available during the launch phase
	 */
	// {
	//  element: <BlankLayout />,
	//  children: [
	//    {
	//      path: '/login',
	//      element: <LoginPage />
	//    },
	//    {
	//      path: '/register',
	//      element: <RegisterPage />
	//    },
	//    {
	//      path: '/forgot-password',
	//      element: <ForgotPasswordPage />
	//    },
	//    {
	//      path: '/new-password',
	//      element: <NewPasswordPage />
	//    }
	//  ]
	// },
	{
		element: <LandingPageLayout />,
		children: [
			{
				path: '/home',
				element: <HomePage />
			},
			{
				path: '/about',
				element: <AboutPage />
			},
			{
				path: '/faq',
				element: <FaqPage />
			},
			{
				path: '/contact',
				element: <ContactPage />
			},
			{
				path: '/privacy-policy',
				element: <PrivacyPolicyPage />
			},
			{
				path: '/terms-conditions',
				element: <TermsAndConditionsPage />
			}
		]
	},
	{
		path: '*',
		element: <Navigate to='/home' replace />
		/**
		 * Uncomment the line below and delete the line above after the launch phase
		 * This enables only home route to be available during the launch phase
		 */
		// element: <Navigate to='/login' replace />
	}
]
