// ** React Imports
import { type ReactNode } from 'react'

// ** Third Party Imports
import * as Tabs from '@radix-ui/react-tabs'
import styled from 'styled-components'

import { textTrunc, media } from '@/shared/styles'

import { Typography } from '../Typography'

interface IProps {
	value: string
	children: ReactNode
}

const StyledTabsTrigger = styled(Tabs.Trigger)`
	all: unset;
	cursor: pointer;
	text-transform: uppercase;

	&[data-state='active'] {
		p {
			font-weight: 600;
			padding-bottom: 4px;
			border-bottom: 2px solid ${({ theme }) => theme.pallette.text.black75};
		}
	}
`

const StyledTypography = styled(Typography)`
	${textTrunc(2)}
	word-break: break-word;
	${media.xs} {
		font-size: 0.8rem;
	}
`

export const TabsTrigger = ({ value, children }: IProps) => {
	return (
		<StyledTabsTrigger value={value}>
			<StyledTypography variant='body1'>{children}</StyledTypography>
		</StyledTabsTrigger>
	)
}
