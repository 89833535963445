import { createAsyncThunk } from '@reduxjs/toolkit'
import { sessionApi, type LoginVariables } from '@/entities/session'
import { type UserDTO, UserRoleCode } from '@/entities/user'

export const loginThunk = createAsyncThunk<
	UserDTO,
	LoginVariables,
	{ state: RootState; rejectValue: string }
>('authentication/login', async (body, { dispatch, rejectWithValue }) => {
	try {
		const data = await dispatch(
			sessionApi.endpoints.login.initiate(body)
		).unwrap()

		if (data.role.code === UserRoleCode.SUPERADMIN) {
			dispatch(sessionApi.endpoints.logout.initiate())
			return rejectWithValue("You don't have permission to access this page")
		}

		return data
	} catch (err) {
		if (typeof err === 'string') {
			return rejectWithValue(err)
		}

		throw new Error('Unknown error')
	}
})
