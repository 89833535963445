// ** Third Party Imports
import styled from 'styled-components'

// ** Component Imports
import { Heading, Typography } from '@/shared/ui'

// ** Utility Imports
import { zIndex } from '@/shared/styles'

export const FooterWrapper = styled.div`
	width: 100%;
	padding: 4rem 2rem;
	z-index: ${zIndex.footer};
	position: relative;
	background-color: ${({ theme }) => theme.pallette.geniiBlack};
`

export const FooterContent = styled.div`
	margin: 0 auto;
	max-width: 74rem;
`

export const Header = styled.div`
	gap: 1rem;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
`

export const JoinWrapper = styled.div`
	gap: 2rem;
	display: flex;
	align-items: center;
`

export const JoinText = styled(Heading)`
	color: ${({ theme }) => theme.pallette.geniiWhite};
`

export const Divider = styled.div`
	width: 100%;
	height: 1px;
	margin: 2rem 0.5rem;
	border-radius: 0.5rem;
	background-color: ${({ theme }) => theme.pallette.primary.greenBackground60};
`

export const FooterText = styled(Typography)`
	color: ${({ theme }) => theme.pallette.geniiWhite};

	& > a {
		text-decoration: none;
	}
`
