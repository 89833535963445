// ** Third Party Imports
import styled from 'styled-components'

export type ColorVariant =
	| 'primary'
	| 'success'
	| 'warning'
	| 'error'
	| 'positive'
	| 'negative'

export const StyledChip = styled.div<{ color: ColorVariant }>`
	display: inline-flex;
	padding: 0.5rem 0.75rem;
	column-gap: 0.25rem;
	align-items: center;
	border-radius: 1rem;

	& > p {
		color: ${({ theme }) => theme.pallette.geniiWhite};
		text-transform: capitalize;
	}

	& > svg {
		width: 1.5rem;
		height: 1.5rem;
		path {
			stroke: ${({ theme }) => theme.pallette.geniiWhite};
		}
	}

	${({ color, theme }) =>
		color === 'primary' && `background-color: ${theme.pallette.primary.main};`}

	${({ color, theme }) =>
		color === 'success' && `background-color: ${theme.pallette.success.main};`}

	${({ color, theme }) =>
		color === 'warning' &&
		`
    background-color: ${theme.pallette.warning.main};

    & > p {
      color: ${theme.pallette.text.black75};
    }

    & > svg {
      path {
        stroke: ${theme.pallette.text.black75};
      }
    }
    `}

	${({ color, theme }) =>
		color === 'error' && `background-color: ${theme.pallette.error.main};`}

  ${({ color, theme }) =>
		color === 'positive' &&
		`
      border: 1px solid ${theme.pallette.primary.main};
      background-color: ${theme.pallette.primary.greenBackground20};

      & > p {
        color: ${theme.pallette.primary.main};
      }
  `}

  ${({ color, theme }) =>
		color === 'negative' &&
		`
      border: 1px solid ${theme.pallette.primary.green};
      background-color: ${theme.pallette.geniiWhite};

      & > p {
        color: ${theme.pallette.primary.green};
      }
  `}
`
