// ** Third Party Imports
import styled, { css } from 'styled-components'
import { ToastContainer } from 'react-toastify'

// ** Component Imports
import { IconButton } from '../IconButton'

// ** Icon Imports
import { AlertTriangle, CheckBroken, XCircleContained } from '../../icons'

export const IconStyles = css`
	width: 1.25rem;
	height: 1.25rem;
`

export const SuccessIcon = styled(CheckBroken)`
	${IconStyles}

	& path {
		stroke: ${({ theme }) => theme.pallette.success.main};
	}
`

export const ErrorIcon = styled(XCircleContained)`
	${IconStyles}

	& path {
		fill: ${({ theme }) => theme.pallette.error.main};
	}
`

export const WarningIcon = styled(AlertTriangle)`
	${IconStyles}

	& path {
		fill: ${({ theme }) => theme.pallette.warning.main};
	}
`

export const StyledIconButton = styled(IconButton)`
	& svg path {
		stroke: ${({ theme }) => theme.pallette.text.black75};
	}

	&:hover {
		& svg path {
			stroke: ${({ theme }) => theme.pallette.text.black};
		}
	}
`

export const StyledToast = styled(ToastContainer)`
	& > div {
		border: 1px solid ${({ theme }) => theme.pallette.primary.greenBackground60};
		box-shadow: 0px 2px 8px 0px rgba(9, 106, 99, 0.25);
		border-radius: 0.5rem;
		background-color: ${({ theme }) => theme.pallette.geniiWhite};
	}

	/* Custom react toastify progress bar color */
	& .custom-toast-progress-bar {
		&.Toastify__progress-bar--error {
			background: ${({ theme }) => theme.pallette.error.main};
		}

		&.Toastify__progress-bar--success {
			background: ${({ theme }) => theme.pallette.success.main};
		}

		&.Toastify__progress-bar--warning {
			background: ${({ theme }) => theme.pallette.warning.main};
		}
	}
`
