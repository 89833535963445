// ** React Imports
import { type ReactNode } from 'react'

// ** Third Party Imports
import * as Tabs from '@radix-ui/react-tabs'
import styled from 'styled-components'

interface IProps {
	children: ReactNode
	ariaLabel?: string
}

export const TabsList = ({ children, ariaLabel }: IProps) => {
	return <StyledTabsList aria-label={ariaLabel}>{children}</StyledTabsList>
}

const StyledTabsList = styled(Tabs.List)`
	gap: 1.75rem;
	display: flex;
	flex-wrap: wrap;
	margin-bottom: 2rem;
`
