import { css } from 'styled-components'

export const textTrunc = (numOfLines = 1) => css`
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	word-break: break-all;
	-webkit-line-clamp: ${numOfLines};
	-webkit-box-orient: vertical;
`
