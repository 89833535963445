import { createAsyncThunk } from '@reduxjs/toolkit'
import { sessionApi } from '@/entities/session'
import { UserRoleCode } from './types'
import { getCurrentUserApi } from '../api/getCurrentUser'
import { type UserDTO } from './types'

export const currentUserThunk = createAsyncThunk<
	UserDTO,
	// eslint-disable-next-line @typescript-eslint/no-invalid-void-type
	void,
	{ state: RootState; rejectValue: string }
>('user/currentUser', async (_, { dispatch, rejectWithValue }) => {
	try {
		const data = await dispatch(
			getCurrentUserApi.endpoints.getCurrentUser.initiate()
		).unwrap()

		if (data.role.code === UserRoleCode.SUPERADMIN) {
			dispatch(sessionApi.endpoints.logout.initiate())
			return rejectWithValue("You don't have permission to access this page.")
		}

		return data
	} catch (err) {
		if (typeof err === 'string') {
			return rejectWithValue(err)
		}

		throw new Error('Unknown error')
	}
})
