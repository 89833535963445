// ** Third Party Imports
import styled from 'styled-components'
import * as Progress from '@radix-ui/react-progress'

interface ProgressProps {
	$height: number
}

export const ProgressRoot = styled(Progress.Root)<ProgressProps>`
	width: 100%;
	height: ${({ $height }) => $height}rem;
	overflow: hidden;
	position: relative;
	border-radius: 4px;
	background-color: ${({ theme }) => theme.pallette.primary.greenBackground};

	// Fix overflow clipping in Safari
	// https://gist.github.com/domske/b66047671c780a238b51c51ffde8d3a0
	transform: translateZ(0);
`

export const ProgressIndicator = styled(Progress.Indicator)`
	width: 100%;
	height: 100%;
	background-color: ${({ theme }) => theme.pallette.primary.main};
`
