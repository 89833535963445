// ** Third Party Imports
import styled from 'styled-components'
import { Link } from 'react-router-dom'

// ** Component Imports
import { Button, Card, Heading, Typography } from '@/shared/ui'

// ** Icon Imports
import { EyeClosed, EyeOpen, Mail, Password } from '@/shared/ui/icons'

export const CardWrapper = styled(Card)`
	width: 26rem;
	padding: 4rem 2rem;
	text-align: center;
	background-color: ${({ theme }) => theme.pallette.geniiWhite};
`

export const CardTitle = styled(Heading)`
	${({ theme }) => `color: ${theme.pallette.text.black75};`}
	margin-bottom: 1.5rem;
`

export const CardSubtitle = styled(Heading)`
	${({ theme }) => `color: ${theme.pallette.text.black75};`}
	margin-bottom: 0.5rem;
`

export const CardText = styled(Typography)`
	color: ${({ theme }) => theme.pallette.text.black75};
	margin-bottom: 1.5rem;
`

export const LoginSettings = styled.div`
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: space-between;

	& .remember-login {
		margin-right: 1rem;
	}
`

export const ForgotPasswordButton = styled(Link)`
	color: ${({ theme }) => theme.pallette.primary.main};
	font-size: 0.625rem;
	font-weight: 600;
	margin-bottom: 1.5rem;
	text-decoration-line: underline;
`

export const SubmitButton = styled(Button)`
	margin-bottom: 1.5rem;
`

export const SignupButton = styled.div`
	${({ theme }) => `color: ${theme.pallette.text.black75};`}
	text-align: center;

	& p {
		display: inline;
	}
`

// Icon styling
export const MailIcon = styled(Mail)`
	top: 0.85rem;
	left: 0.75rem;
	width: 1.25rem;
	height: 1.25rem;
	position: absolute;

	& path {
		stroke: #848898;
	}
`

export const PasswordIcon = styled(Password)`
	top: 1rem;
	left: 0.75rem;
	width: 1.25rem;
	height: 1.25rem;
	position: absolute;

	& path {
		stroke: #848898;
	}
`

export const EyeOpenIcon = styled(EyeOpen)`
	top: 1rem;
	right: 0.75rem;
	width: 1rem;
	height: 1rem;
	position: absolute;

	& path,
	& circle {
		stroke: #848898;
	}

	&:hover {
		& path,
		& circle {
			stroke: #091231;
		}
	}
`

export const EyeClosedIcon = styled(EyeClosed)`
	top: 1rem;
	right: 0.75rem;
	width: 1rem;
	height: 1rem;
	position: absolute;

	& path {
		stroke: #848898;
	}

	&:hover {
		& path {
			stroke: #091231;
		}
	}
`
