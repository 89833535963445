// ** Third Party Imports
import styled from 'styled-components'

// ** Component Imports
import { Typography } from '../Typography'

// ** Icon Imports
import { Upload } from '@/shared/ui/icons'

export const FileUploadWrapper = styled.div`
	gap: 0.5rem;
	border: 1px dashed ${({ theme }) => theme.pallette.text.black50};
	cursor: pointer;
	display: flex;
	padding: 2rem 0.75rem;
	box-shadow: 0px 1px 3px 0px rgba(132, 136, 152, 0.15);
	align-items: center;
	border-radius: 0.5rem;
	justify-content: center;
	background-color: ${({ theme }) => theme.pallette.geniiWhite};
`

export const FileUploadText = styled(Typography)`
	color: ${({ theme }) => theme.pallette.text.black50};
`

export const FileUploadIcon = styled(Upload)`
	width: 1rem;
	height: 1rem;

	& path {
		stroke: ${({ theme }) => theme.pallette.text.black50};
	}
`
