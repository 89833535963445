// ** React Imports
import React, { useImperativeHandle, useRef } from 'react'

// ** Icon Imports
import { Calendar } from '../../icons'

interface IProps {
	id: string
	label?: string
	startDate: Date | null
}

export const DatePickerInput = React.forwardRef<HTMLInputElement, IProps>(
	(props, ref) => {
		const innerRef = useRef<HTMLInputElement>(null)

		useImperativeHandle(ref, () => innerRef?.current as HTMLInputElement)

		return (
			<div className='custom-date-picker-input'>
				<input {...props} id={props.id} ref={innerRef} placeholder=' ' />
				<label {...props} htmlFor={props.id}>
					{props.label ?? 'Year'}
				</label>
				{!props.startDate && <Calendar />}
			</div>
		)
	}
)
