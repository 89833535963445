export const themeConfig = {
	pallette: {
		primary: {
			main: '#096A63',
			disabled: '#7E8F8E',
			green: '#26A199',
			greenBackground: 'rgba(171, 212, 210, 1)',
			greenBackground20: 'rgba(171, 212, 210, 0.2)',
			greenBackground50: 'rgba(171, 212, 210, 0.5)',
			greenBackground60: 'rgba(171, 212, 210, 0.6)'
		},
		secondary: {
			main: '#F6D17D',
			yellow: '#F6C24C',
			yellowDisabled: '#E3C173',
			yellowBackground: '#FAF8EC'
		},
		success: {
			main: '#7EAD82',
			second: '#A4E0A9'
		},
		error: {
			main: '#DB8F8C',
			second: '#ED9B98'
		},
		warning: {
			main: '#F6CE9D',
			second: '#FFEDD6'
		},
		text: {
			black: '#091231',
			black75: '#474D65',
			black50: '#848898'
		},
		background: {
			sidebarLight: 'rgba(171, 212, 210, 0.2)',
			sidebarDark: 'rgba(171, 212, 210, 0.6)'
		},
		geniiBlack: '#091231',
		geniiWhite: '#FAFAFA',
		black505: '#F1F1F2',
		gray: 'E1DEDB'
	}
}
