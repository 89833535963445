// ** Third Party Imports
import styled from 'styled-components'
import * as RadixTooltip from '@radix-ui/react-tooltip'

export const TooltipArrow = styled(RadixTooltip.Arrow)`
	fill: #fafafa;
`

export const TooltipContent = styled(RadixTooltip.Content)`
	padding: 0.5rem;
	max-width: 12.5rem;
	box-shadow: 0px 2px 8px 0px rgba(9, 106, 99, 0.25);
	background: #fafafa;
	border-radius: 0.5rem;

	& p {
		width: 100%;
		overflow-x: auto;
		white-space: nowrap;

		::-webkit-scrollbar {
			height: 1.5px;
		}

		::-webkit-scrollbar-thumb {
			background-color: ${({ theme }) => theme.pallette.primary.green};
			border-radius: 1rem;
		}
	}
`

export const TooltipTrigger = styled(RadixTooltip.Trigger)`
	cursor: default;
`
