// ** Third Party Imports
import styled from 'styled-components'

export const DatePickerWrapper = styled.div<{
	$isError: boolean
	$isValid: boolean
	$fullWidth: boolean
}>`
	display: flex;
	position: relative;
	font-family: inherit;

	${({ $fullWidth }) =>
		$fullWidth &&
		`
    & > label {
      width: 100%;
    }

    & .react-datepicker-wrapper {
      width: 100%;

      & input {
        width: 100%;
      }
    }
  `}

	/* Input field native wrapper class */
	& .react-datepicker-wrapper {
		/* Custom input wrapper class */
		& .custom-date-picker-input {
			/* React date picker label */
			& label {
				top: 0.813rem;
				left: 0.75rem;
				cursor: text;
				position: absolute;
				font-size: 0.875rem;
				transition: all 0.2s ease;
				user-select: none;
				background-color: ${({ theme }) => theme.pallette.geniiWhite};
				font-weight: 400;
				/* Change label color based on selected value */
				color: ${({ theme, $isValid }) =>
					$isValid ? theme.pallette.success.main : theme.pallette.text.black50};
			}

			/* React date picker input field */
			& input {
				cursor: text;
				border: 1px solid;
				outline: none;
				padding: 0.75rem;
				font-size: 0.875rem;
				box-shadow: 0px 1px 3px 0px rgba(132, 136, 152, 0.15);
				background-color: ${({ theme }) => theme.pallette.geniiWhite};
				border-radius: 0.5rem;
				/* Change input border color based on selected value */
				border-color: ${({ theme, $isValid }) =>
					$isValid ? theme.pallette.success.main : theme.pallette.text.black50};

				// Floating label on focus
				&:focus,
				&:not(:placeholder-shown) {
					border-color: ${({ theme, $isValid }) =>
						$isValid
							? theme.pallette.success.main
							: theme.pallette.text.black75};

					~ label {
						top: -0.438rem;
						left: 0.75rem;
						color: ${({ theme, $isValid }) =>
							$isValid
								? theme.pallette.success.main
								: theme.pallette.text.black75};
						padding: 0 0.25rem;
						font-size: 0.625rem;
					}

					~ svg {
						path {
							stroke: ${({ theme, $isValid }) =>
								$isValid
									? theme.pallette.success.main
									: theme.pallette.text.black75};
						}
					}
				}
			}

			/* Custom dropdown icon styling */
			& > svg {
				top: 0.938rem;
				right: 0.75rem;
				width: 1rem;
				height: 1rem;
				/* cursor: default; */
				position: absolute;

				/* Change icon color based on selected value */
				& path {
					stroke: ${({ theme, $isValid }) =>
						$isValid
							? theme.pallette.success.main
							: theme.pallette.text.black50};
				}
			}

			/* Invalid styling */
			${({ $isError, theme }) =>
				$isError &&
				`
            & label {
              color: ${theme.pallette.error.main} !important;
            }
            & input {
              border-color: ${theme.pallette.error.main} !important;
            }

            & > svg {
              path {
                stroke: ${theme.pallette.error.main} !important;
              }
            }
          `}
		}

		/* Custom clear input button */
		& .react-datepicker__close-icon {
			top: 0.75rem;
			height: unset;
			padding-right: 0.75rem;

			&::after {
				color: ${({ theme }) => theme.pallette.text.black75};
				font-size: 1rem;
				background-color: transparent;
			}
		}
	}

	/* React date picker dropdown */
	& .react-datepicker-popper {
		padding-top: 0.5rem;

		& .react-datepicker {
			/* Customize dropdown borders, background, fonts */
			color: ${({ theme }) => theme.pallette.text.black75};
			border: 1px solid ${({ theme }) => theme.pallette.text.black50};
			font-size: 0.875rem;
			box-shadow: 0px 2px 0.5rem 0px rgba(9, 106, 99, 0.25);
			font-weight: 400;
			font-family: inherit;
			border-radius: 0.5rem;
			background-color: ${({ theme }) => theme.pallette.geniiWhite};

			/* React datepicker header */
			& .react-datepicker__header {
				/* border: none; */
				/* padding: 0 1rem; */
				padding: 0;
				border-bottom: 0.5px solid
					${({ theme }) => theme.pallette.primary.greenBackground};
				background-color: ${({ theme }) => theme.pallette.geniiWhite};
				border-top-left-radius: 0.5rem;
				border-top-right-radius: 0.5rem;

				/* Days of the week wrapper */
				& .react-datepicker__day-names {
					margin-bottom: 0;
				}

				/* Day of the week */
				& .react-datepicker__day-name {
					margin: 0;
					text-transform: uppercase;
					color: ${({ theme }) => theme.pallette.text.black50};
					width: 2.57144rem;
					font-size: 0.875rem;
					line-height: 2rem;
				}
			}

			/* Month days wrapper */
			& .react-datepicker__month {
				margin: 0 1rem 1rem;

				/* Single day styling */
				& .react-datepicker__day {
					margin: 0;
					width: 2.57144rem;
					line-height: 2rem;

					/* Selected day styling */
					&.react-datepicker__day--selected {
						width: 2rem;
						margin: 0 0.285715rem;
						border-radius: 50%;
						background-color: ${({ theme }) => theme.pallette.primary.main};
					}

					/* Native initially selected styling */
					&.react-datepicker__day--keyboard-selected,
					&.react-datepicker__day--keyboard-selected:hover {
						background-color: #f0f0f0;
					}
				}
			}

			/* Custom Year/Month dropdown */
			& .react-datepicker__month-year-dropdown {
				max-height: 200px !important;
				overflow-y: scroll !important;
			}

			/* Stye days outside of month */
			& .react-datepicker__day--outside-month {
				opacity: 0.4;
			}
		}
	}
`
