// ** Third Party Imports
import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'

interface IconButtonProps {
	$width: number
	$height: number
	$disabled?: boolean
}

const styles = css<IconButtonProps>`
	border: none;
	cursor: pointer;
	display: flex;
	outline: none;
	background: none;
	flex-shrink: 0;

	/* Custom icon height & width */
	svg {
		width: ${({ $width }) => $width}rem;
		height: ${({ $height }) => $height}rem;
	}

	${({ $disabled }) =>
		$disabled &&
		`
      cursor: default;
      opacity: 0.5;
      pointer-events: none;
  `}
`

export const StyledButton = styled.button<IconButtonProps>`
	${styles}
`

export const StyledLink = styled(Link)<IconButtonProps>`
	${styles}
`
