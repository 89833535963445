// ** Third Party Imports
import styled from 'styled-components'

// ** Icon Imports
import { EyeClosed, EyeOpen, Password } from '../../icons'

export const PasswordIcon = styled(Password)`
	top: 1rem;
	left: 0.75rem;
	width: 1.25rem;
	height: 1.25rem;
	position: absolute;

	& path {
		stroke: #848898;
	}
`

export const EyeOpenIcon = styled(EyeOpen)`
	top: 1rem;
	right: 0.75rem;
	width: 1rem;
	height: 1rem;
	position: absolute;

	& path,
	& circle {
		stroke: #848898;
	}

	&:hover {
		& path,
		& circle {
			stroke: #091231;
		}
	}
`

export const EyeClosedIcon = styled(EyeClosed)`
	top: 1rem;
	right: 0.75rem;
	width: 1rem;
	height: 1rem;
	position: absolute;

	& path {
		stroke: #848898;
	}

	&:hover {
		& path {
			stroke: #091231;
		}
	}
`
