// ** Third Party Imports
import { NavLink } from 'react-router-dom'

// ** Component Imports
import { Button, IconButton } from '@/shared/ui'

// ** Icon Imports
import { Logo } from '@/shared/ui/icons'

// ** Styled Components
import {
	Divider,
	FooterContent,
	FooterText,
	FooterWrapper,
	Header,
	JoinText,
	JoinWrapper
} from './styles'

export const Footer = () => {
	return (
		<FooterWrapper>
			<FooterContent>
				<Header>
					<IconButton
						to='/home'
						icon={<Logo />}
						width={6.875}
						height={2.813}
						ariaLabel='Go to home page'
					/>
					<JoinWrapper>
						<JoinText variant='h4'>Let&apos;s do it! —</JoinText>
						<Button to='/contact' label='Contact Us' />
					</JoinWrapper>
				</Header>
				<Divider />
				<FooterText>
					© 2024 FortunAI |{' '}
					<NavLink to='/privacy-policy'>Privacy Policy</NavLink> |{' '}
					<NavLink to='/terms-conditions'>Terms and Conditions</NavLink>
				</FooterText>
			</FooterContent>
		</FooterWrapper>
	)
}
